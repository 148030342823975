const buildIndex = (objects, keyGetter, valueGetter, multi = false) => {
  let index = {}

  for (let i = 0; i < objects.length; i += 1) {
    let obj = objects[i]
    let key = keyGetter(obj)

    let value = valueGetter ? valueGetter(obj) : obj

    if (multi) {
      if (!index[key]) {
        index[key] = []
      }

      index[key].push(value)
    } else {
      index[key] = value
    }
  }

  return index
}

module.exports = {
  buildIndex,
}
