import * as Sentry from '@sentry/browser'

export const identifyUser = user => {
  Sentry.setUser({ email: user.email })
}

export const convert = user => {
  if (process.env.NODE_ENV === 'production' && window.rewardful) {
    window.rewardful('convert', { email: user.email })
    console.info('Rewardful conversion tracked for', user.email)
  } else {
    console.info('Conversion not tracked for', user.email)
  }
}
