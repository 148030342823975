import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import * as API from '../../../utils/api'

import {
  fetchSubscription,
  getSubscription,
} from '../../../ducks/subscriptions'

import { getCurrentOrganization } from '../../../ducks/users'

import Card from '../../Shared/LegacyCard'
import EmptyState from '../../Shared/EmptyState'
import Button from '../../Shared/Button'

import styles from './Billing.module.css'

const ONE_DAY = 60 * 60 * 24 * 1000

export const Trial = function BillingTrial({ subscription }) {
  const currentDate = +new Date()
  const endDate = new Date(subscription.trial_end * 1000)

  const daysRemaining = Math.max(
    0,
    Math.round((endDate - currentDate) / ONE_DAY)
  )

  const planName = 'Inventora Business'

  return (
    <>
      <h3>You are currently trialing {planName}</h3>
      <p>
        {`You have ${daysRemaining} day${
          daysRemaining === 1 ? '' : 's'
        } remaining in your free trial`}
      </p>
    </>
  )
}

export const Subscription = function BillingSubscription({
  subscription,
  organization,
}) {
  const [cancelling, setCancelling] = useState(false)
  const [loadingPortal, setLoadingPortal] = useState(false)

  const handleCancel = useCallback(async () => {
    if (
      !window.confirm(
        'Are you sure you want to cancel your subscription?\n\nSome features may become unavailable.'
      )
    ) {
      return
    }

    setCancelling(true)

    try {
      await API.cancelSubscription(organization.id)

      window.location.reload()
    } catch (err) {
      window.alert(
        'There was an error cancelling your subscription. Please contact support at inventorahq@gmail.com'
      )
    }

    setCancelling(false)
  }, [organization])

  const goToPortal = useCallback(async () => {
    setLoadingPortal(true)

    try {
      const { url } = (await API.createPortalSession(organization.id)).data
      window.location = url
    } catch (err) {
      setLoadingPortal(false)

      const error =
        (err.response && err.response.data && err.response.data.error) ||
        'An unknown error occurred'

      window.alert(error)
    }
  }, [organization])

  if (!subscription) {
    return <EmptyState tight loading />
  }

  const endDate = subscription.current_period_end * 1000
  const amountRaw = subscription.plan.amount / 100
  const amount = amountRaw.toFixed(2)
  const currentDate = +new Date() / 1000

  const activeTrial =
    subscription.trial_end && subscription.trial_end > currentDate

  const planName = 'Inventora Business'

  return (
    <div className={styles.billingHook}>
      {activeTrial ? (
        <Trial subscription={subscription} />
      ) : (
        <>
          <h3>{`You are currently subscribed to ${planName}`}</h3>
          <p>
            {`Your next bill for $${amount} will be automatically charged on ${moment(
              new Date(endDate)
            ).format('LL')}.`}
          </p>
        </>
      )}
      <Button.Group className={styles.actions}>
        <Button onClick={goToPortal} loading={loadingPortal}>
          Manage Subscription
        </Button>
        <Button onClick={handleCancel} loading={cancelling}>
          {activeTrial ? 'Cancel Trial' : 'Cancel Subscription'}
        </Button>
      </Button.Group>
    </div>
  )
}

export const FreePlan = function FreePlan() {
  return (
    <div className={styles.billingHook}>
      <h3>You are currently on the free plan</h3>
      <p>
        Upgrade to Inventora Business to access CSV downloads, reports, and
        more.
      </p>
      <div className={styles.actions}>
        <Button className={styles.button} to="/settings/upgrade">
          Upgrade Now
        </Button>
      </div>
    </div>
  )
}

export default function BillingSettings() {
  const dispatch = useDispatch()
  const organization = useSelector(getCurrentOrganization)
  const subscriptionInfo = useSelector(getSubscription(organization.id))

  useEffect(() => {
    dispatch(fetchSubscription(organization.id))
  }, [dispatch, organization.Id])

  const { subscription } = subscriptionInfo || {}

  return (
    <Card>
      <h2>Billing</h2>
      {organization.activePlan === 'manual' ? (
        <div className={styles.description}>
          <p>
            Your plan is billed outside of Inventora. Please contact your
            account manager to adjust billing.
          </p>
        </div>
      ) : organization.activePlan && (!subscriptionInfo || subscription) ? (
        <div className={styles.description}>
          <Subscription
            subscription={subscription}
            organization={organization}
          />
        </div>
      ) : (
        <FreePlan />
      )}
    </Card>
  )
}
