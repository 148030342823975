import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Icon from '../Icon'

import Loading from '../Loading'

import styles from './Button.module.css'

const Button = function Button(props) {
  const [waiting, setWaiting] = useState(false)

  let {
    className,
    to,
    target,
    icon,
    text,
    xs,
    small,
    large,
    disabled,
    primary,
    danger,
    loading,
    component,
    children,
    loadingText,
    block,
    onClick,
    ...otherProps
  } = props

  const handleClick = useCallback(
    (...args) => {
      if (!onClick) {
        return
      }

      const result = onClick(...args)

      if (result instanceof Promise) {
        setWaiting(true)

        result
          .catch(err => {
            console.error(err)
          })
          .then(() => {
            setWaiting(false)
          })
      }

      return result
    },
    [onClick]
  )

  loading = loading || waiting
  disabled = disabled || loading

  if (loading) {
    icon = 'placeholder'
  }

  let ButtonComponent = to ? Link : 'button'

  if (to && target === '_blank') {
    ButtonComponent = 'a'

    otherProps.href = to
    otherProps.target = target
  }

  if (component) {
    ButtonComponent = component
  }

  children = (loading && loadingText) || children

  const clickHandler = onClick ? handleClick : undefined

  const type =
    otherProps.type || (ButtonComponent === 'button' && onClick)
      ? 'button'
      : undefined

  return (
    <ButtonComponent
      {...otherProps}
      onClick={clickHandler}
      to={to}
      type={type}
      className={classNames(styles.button, className, {
        [styles.text]: text,
        [styles.xs]: xs,
        [styles.small]: small,
        [styles.large]: large,
        [styles.disabled]: disabled,
        [styles.loading]: loading,
        [styles.primary]: primary,
        [styles.danger]: danger,
        [styles.block]: block,
      })}
    >
      {icon && <Icon icon={icon} className={styles.icon} />}
      {children && <span className={styles.content}>{children}</span>}
      {loading && (
        <div className={styles.loader}>
          <Loading small />
        </div>
      )}
    </ButtonComponent>
  )
}

Button.Group = function ButtonGroup({ className, align = 'none', children }) {
  return (
    <div
      className={classNames(styles.group, styles[`align-${align}`], className)}
    >
      {children}
    </div>
  )
}

export default Button
