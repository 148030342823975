import moment from 'moment'

export const formatValue = (field, value) => {
  const { type } = field

  switch (type) {
    case 'json':
    case 'jsonb':
      return JSON.stringify(value)
    case 'timestamptz':
      return new Date(value).toLocaleString()
    case 'date':
      return moment.utc(value).format('l')
  }

  return String(value)
}
