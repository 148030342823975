import throttle from 'lodash/throttle'
import * as API from '../utils/api'

const FETCH = 'FETCH_DATA'

const INITIAL_STATE = {}

let id = 0

const getId = () => {
  id += 1

  return id
}

// Reducer

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH}_PENDING`: {
      const { viewId, uid } = action.meta

      return {
        ...state,
        [viewId]: {
          ...state[viewId],
          loading: true,
          uid,
        },
      }
    }

    case `${FETCH}_FULFILLED`: {
      const { viewId, uid } = action.meta

      if (state[viewId]?.uid !== uid) {
        return state
      }

      return {
        ...state,
        [viewId]: action.payload.data,
      }
    }

    case `${FETCH}_REJECTED`: {
      const { viewId, uid } = action.meta
      const message = action.payload.message
      const response = { ...action.payload.response }

      if (state[viewId]?.uid !== uid) {
        return state
      }

      return {
        ...state,
        [viewId]: {
          error: {
            message,
            response,
          },
        },
      }
    }

    default:
      return state
  }
}

// Actions

const fetchSub = throttle(
  (dispatch, viewId, search, page) => {
    dispatch({
      type: FETCH,
      payload: API.fetchViewData(viewId, search, page),
      meta: { viewId, uid: getId() },
    })
  },
  500,
  {
    leading: true,
    trailing: true,
  }
)

export const fetchData =
  (viewId, search, page = 1) =>
  dispatch => {
    fetchSub(dispatch, viewId, search, page)
  }

// Selectors

export const getViewData = viewId => state => {
  return state.data[viewId]
}
