const getCompletedQuantity = taskResource => {
  return (taskResource.TaskBatches || [])
    .map(b => b.quantity)
    .reduce((a, b) => a + b, 0)
}

const getCompleted = taskResource => {
  const quantityCompleted = getCompletedQuantity(taskResource)
  const completed = quantityCompleted >= taskResource.quantity

  return completed
}

module.exports = {
  getCompletedQuantity,
  getCompleted,
}
