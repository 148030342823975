import React from 'react'
import { reduxForm, Field } from 'redux-form'

import WrappedInput from '../../Shared/Forms/WrappedInput'
import Button from '../../Shared/Button'
import Card from '../../Shared/LegacyCard'
import FormError from '../../Shared/Forms/FormError'

import styles from '../Auth.module.css'

const FORM_NAME = 'forgotPassword'

const validate = data => {
  const errors = {}

  if (!data.email) {
    errors.email = 'Please enter your email'
  }

  return errors
}

const Form = function LoginForm({ handleSubmit, submitting, error }) {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <div className={styles.inputs}>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            component={WrappedInput}
          />
        </div>
        {error && <FormError>{error}</FormError>}
        <Button primary block loading={submitting}>
          Send Reset Link
        </Button>
      </Card>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(Form)
