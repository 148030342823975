import React, { useCallback } from 'react'
import classNames from 'classnames'
import styles from './YearlySwitch.module.css'

export default function YearlySwitch({ selected, onChange }) {
  const handleToggle = useCallback(() => {
    onChange(!selected)
  }, [selected, onChange])

  return (
    <div className={classNames(styles.wrapper, selected && styles.selected)}>
      <p className={classNames(!selected && styles.activeLabel)}>Pay Monthly</p>
      <div className={styles.switch} onClick={handleToggle}>
        <div className={styles.indicator} />
      </div>
      <p className={selected && styles.activeLabel}>Pay Yearly - Save 20%</p>
    </div>
  )
}
