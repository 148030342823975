import React from 'react'
import Button from '../../Shared/Button'
import styles from './Error.module.css'

export default function Error({ error }) {
  let { message } = error
  let cta

  if (
    error.response &&
    error.response.status === 400 &&
    error.response?.data?.error
  ) {
    message = error.response.data.error

    if (error.response.data.type === 'databaseAuth') {
      cta = 'settings'
    }
  }

  return (
    <>
      <div className={styles.error}>
        <span>{message}</span>
        <br />
        <br />
        {error.context && <span>{error.context.styledContext}</span>}
      </div>
      {cta === 'settings' && (
        <div className={styles.errorCTA}>
          <Button to="/settings">Go to settings</Button>
        </div>
      )}
    </>
  )
}
