import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import styles from './Card.module.css'

export default function Card(props) {
  const {
    children,
    to,
    actions,
    component,
    className,
    noPad,
    leftAligned,
    bordered,
    ...otherProps
  } = props

  const Component = component || (to ? Link : 'div')

  return (
    <Component
      {...otherProps}
      className={classNames(styles.card, className, {
        [styles.link]: to,
        [styles.noPad]: noPad,
        [styles.leftAligned]: leftAligned,
        [styles.bordered]: bordered,
      })}
      to={to}
    >
      <div className={styles.cardSub}>
        {children}
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </Component>
  )
}
