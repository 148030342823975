import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import classNames from 'classnames'

import {
  logout,
  getCurrentUser,
  getCurrentOrganization,
  getOrganizations,
} from '../../../../ducks/users'

import SwitchOrg from './SwitchOrg'

import styles from './AccountMenu.module.css'

const cancelEvent = e => {
  e.stopPropagation()
}

const MenuLink = function AccountMenuLink({ text, to, onClose }) {
  return (
    <Link className={styles.menuItem} to={to} onClick={onClose}>
      {text}
    </Link>
  )
}

const Menu = function AccountMenuMenu({ onClose }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(getCurrentUser)
  const currentOrg = useSelector(getCurrentOrganization)
  const organizations = useSelector(getOrganizations)

  const owner =
    currentOrg &&
    currentOrg.OrganizationUser &&
    currentOrg.OrganizationUser.owner

  const handleLogout = () => {
    dispatch(logout())
    history.push('/login')
  }

  if (!user) {
    return null
  }

  return (
    <>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.menu}>
        <div className={styles.menuItem}>
          <h5>Signed in as</h5>
          <p className={styles.email}>{user.email}</p>
        </div>
        {organizations.length > 1 && (
          <div
            className={classNames(styles.menuItem, styles.switchOrg)}
            onClick={cancelEvent}
            onTouchEnd={cancelEvent}
          >
            <SwitchOrg onSwitch={onClose} />
          </div>
        )}
        {owner && <MenuLink to="/settings" text="Settings" onClose={onClose} />}
        <MenuLink to="/account" text="My Profile" onClose={onClose} />
        <hr />
        <a
          href="https://help.inventora.co"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.menuItem}
          onClick={cancelEvent}
        >
          Help & Support
        </a>
        <hr />
        {user.admin && (
          <>
            <MenuLink to="/admin/users" text="Admin" onClose={onClose} />
            <hr />
          </>
        )}
        <a className={styles.menuItem} onClick={handleLogout}>
          Logout
        </a>
        <hr />
        <div className={styles.menuFooter}>
          <a target="_blank" href="/terms.html" onClick={cancelEvent}>
            Terms of Service
          </a>
          <span className={styles.menuFooterDivider}>•</span>
          <a target="_blank" href="/privacy.html" onClick={cancelEvent}>
            Privacy Policy
          </a>
        </div>
      </div>
    </>
  )
}

export default function AccountMenu() {
  const [expanded, setExpanded] = useState(false)

  const organization = useSelector(getCurrentOrganization)

  const toggle = e => {
    e.stopPropagation()

    setExpanded(!expanded)
  }

  if (!organization) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.trigger,
          expanded && styles.triggerExpanded
        )}
        onClick={toggle}
        onMouseUp={cancelEvent}
      >
        <div className={styles.avatar} />
        {organization.name}
      </div>
      {expanded && <Menu onClose={toggle} />}
    </div>
  )
}
