import React from 'react'
import styles from './Error.module.css'
import Button from '../Shared/Button'

export default function ErrorFallback() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h1>An unexpected error occurred</h1>
        <p>
          Our team has been notified and we’ll try to fix it as quickly as
          possible!
        </p>
        <Button primary component="a" href="/">
          Back Home
        </Button>
      </div>
    </div>
  )
}
