import { buildIndex } from '@sqldash/common'

import * as API from '../../utils/api'
import { FETCH_CURRENT_USER } from '../users'

const UPDATE = 'UPDATE_ORGANIZATION'

const INITIAL_STATE = {}

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${UPDATE}_FULFILLED`) {
    let org = action.payload.data

    return {
      ...state,
      [org.id]: {
        ...state[org.id],
        ...org,
      },
    }
  }

  if (action.type === `${FETCH_CURRENT_USER}_FULFILLED`) {
    let { Organizations: orgs } = action.payload.data

    return {
      ...state,
      ...buildIndex(orgs, org => org.id),
    }
  }

  return state
}

// Actions

export const updateOrganization = (orgId, org) => ({
  type: UPDATE,
  payload: API.updateOrganization(orgId, org),
})

// Selector

export const getOrganization = organizationId => state => {
  return state.organizations[organizationId]
}
