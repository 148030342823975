import { sort, buildIndex } from '@sqldash/common'
import * as API from '../utils/api'
import { assignDepth, filterFolders } from '../utils/folders'

const FETCH = 'FETCH_VIEWS'
const CREATE = 'CREATE_VIEW'
const UPDATE = 'UPDATE_VIEW'
const CREATE_FOLDER = 'CREATE_FOLDER'
const UPDATE_FOLDER = 'UPDATE_FOLDER'
const DELETE_FOLDER = 'DELETE_FOLDER'
const TOGGLE_FOLDER = Symbol('TOGGLE_FOLDER')
const SEARCH = Symbol('SEARCH_VIEWS')

const INITIAL_STATE = {
  loading: false,
  views: {},
  folders: {},
  expanded: {},
  searchTerm: '',
  searchExpanded: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH}_PENDING`: {
      return { ...state, loading: true }
    }

    case `${FETCH}_REJECTED`: {
      return { ...state, loading: false }
    }

    case `${FETCH}_FULFILLED`: {
      const { views, folders } = action.payload.data

      return {
        ...state,
        views: buildIndex(views, itm => itm.id),
        folders: buildIndex(
          folders,
          itm => itm.id,
          itm => ({ ...itm, __isFolder__: true })
        ),
        loading: false,
      }
    }

    case `${UPDATE}_PENDING`: {
      const { viewId, data } = action.meta
      const previous = state.views[viewId]

      return {
        ...state,
        views: {
          ...state.views,
          [viewId]: {
            ...previous,
            ...data,
            previous,
          },
        },
      }
    }

    case `${CREATE}_FULFILLED`:
    case `${UPDATE}_FULFILLED`: {
      const view = action.payload.data

      return {
        ...state,
        views: {
          ...state.views,
          [view.id]: view,
        },
      }
    }

    case `${UPDATE}_REJECTED`: {
      const { viewId } = action.meta
      const { previous } = state.views[viewId]

      return {
        ...state,
        views: {
          ...state.views,
          [viewId]: previous,
        },
      }
    }

    case `${CREATE_FOLDER}_FULFILLED`:
    case `${UPDATE_FOLDER}_FULFILLED`: {
      const folder = action.payload.data

      return {
        ...state,
        folders: {
          ...state.folders,
          [folder.id]: {
            ...folder,
            __isFolder__: true,
          },
        },
      }
    }

    case `${DELETE_FOLDER}_FULFILLED`: {
      const { folderId } = action.meta

      const folders = { ...state.folders }
      delete folders[folderId]

      return {
        ...state,
        folders,
      }
    }

    case TOGGLE_FOLDER: {
      const { folderId } = action
      const prev = state.expanded[folderId] || state.searchExpanded[folderId]
      const expanded = !prev
      let { searchExpanded } = state

      if (!expanded && searchExpanded[folderId]) {
        searchExpanded = { ...searchExpanded, [folderId]: false }
      }

      return {
        ...state,
        searchExpanded,
        expanded: {
          ...state.expanded,
          [folderId]: expanded,
        },
      }
    }

    case SEARCH: {
      const { searchTerm } = action
      const hierarchy = getNavHierarchy({ views: state })
      const [, forceExpand] = filterFolders(hierarchy, searchTerm)

      const searchExpanded = buildIndex(
        forceExpand,
        itm => itm,
        () => true
      )

      return {
        ...state,
        searchTerm,
        searchExpanded,
      }
    }
  }

  return state
}

// Actions

export const fetchViews = orgId => ({
  type: FETCH,
  payload: API.fetchViews(orgId),
})

export const createView = (orgId, data) => ({
  type: CREATE,
  payload: API.createView(orgId, data),
})

export const updateView = (viewId, data) => ({
  type: UPDATE,
  payload: API.updateView(viewId, data),
  meta: { viewId, data },
})

export const createFolder = (orgId, data) => (dispatch, getState) => {
  const order = getNextPosition(getState())

  return dispatch({
    type: CREATE_FOLDER,
    payload: API.createFolder(orgId, { ...data, order }),
  })
}

export const updateFolder = (folderId, data) => ({
  type: UPDATE_FOLDER,
  payload: API.updateFolder(folderId, data),
})

export const deleteFolder = folderId => ({
  type: DELETE_FOLDER,
  payload: API.deleteFolder(folderId),
  meta: { folderId },
})

export const updateAny = data => {
  const { id } = data

  return data.__isFolder__ ? updateFolder(id, data) : updateView(id, data)
}

export const toggleFolder = folderId => ({
  type: TOGGLE_FOLDER,
  folderId,
})

export const searchViews = searchTerm => ({
  type: SEARCH,
  searchTerm,
})

// Selectors

export const getViews = state => {
  return sort(Object.values(state.views.views), itm =>
    (itm.name || 'untitled').toLowerCase()
  )
}

export const getView = viewId => state => {
  return state.views.views[viewId]
}

export const getNavHierarchy = state => {
  const views = Object.values(state.views.views)
  const folders = Object.values(state.views.folders)
  const { expanded, searchTerm, searchExpanded } = state.views

  const map = {}

  const allItems = [...views, ...folders].map(itm => {
    const result = {
      id: itm.id,
      children: itm.__isFolder__ ? [] : null,
      expanded: !!(expanded[itm.id] || searchExpanded[itm.id]),
      data: itm,
    }

    map[itm.id] = result

    return result
  })

  const topLevel = allItems.filter(itm => !itm.data.FolderId)
  const others = allItems.filter(itm => itm.data.FolderId)

  for (const itm of others) {
    const parent = map[itm.data.FolderId]

    itm.parentFolder = parent

    if (!parent || !parent.data.__isFolder__ || parent.id === itm.id) {
      topLevel.push(itm)

      continue
    }

    parent.children.push(itm)
  }

  for (const itm of Object.values(map)) {
    if (itm.children) {
      itm.children = sort(itm.children, itm => itm.data.order || 0)
    }
  }

  const results = sort(topLevel, itm => itm.data.order || 0)

  assignDepth(results)

  return filterFolders(results, searchTerm)[0]
}

export const getNextPosition = state => {
  const views = Object.values(state.views.views)
  const folders = Object.values(state.views.folders)

  const topLevel = sort([...views, ...folders], itm => -itm.order)
  const last = topLevel[0]

  return (last?.order || 0) + 1
}

export const getSearchTerm = state => {
  return state.views.searchTerm || ''
}

export const getFolder = folderId => state => {
  return state.views.folders[folderId]
}
