import React from 'react'
import Icon from '../Icon'
import styles from './InfoBubble.module.css'

export default function InfoBubble({ children }) {
  return (
    <span className={styles.wrapper}>
      <span className={styles.icon}>
        <Icon icon="info" color="lightGrey" />
      </span>
      <span className={styles.popout}>{children}</span>
    </span>
  )
}
