import * as API from './api'

let stripe = null

export const getStripe = () => {
  if (!stripe) {
    stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)
  }

  return stripe
}

export const subscribe = async (
  orgId,
  planName,
  successURL,
  backURL,
  yearly
) => {
  const session = await API.createCheckoutSession(
    orgId,
    planName,
    successURL,
    backURL
  )

  const stripe = getStripe()

  const result = await stripe.redirectToCheckout({
    sessionId: session.data.sessionId,
  })

  if (result.error) {
    throw result.error
  }
}

export default getStripe
