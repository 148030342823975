import React, { useCallback } from 'react'
import deepEqual from 'deep-equal'
import pick from 'lodash/pick'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'

import { useSearchTerm } from '../../../utils/hooks'
import { getView, updateView } from '../../../ducks/views'
import { fetchData } from '../../../ducks/data'
import Form from './Form'

const FIELDS = ['name', 'emoji', 'DatabaseId', 'query']
const REFETCH_FIELDS = ['DatabaseId', 'query']

export default function Query() {
  const dispatch = useDispatch()
  const { viewId } = useParams()
  const view = useSelector(getView(viewId))
  const { query, visualization } = view
  const searchTerm = useSearchTerm()

  const handleFetch = useCallback(
    (page = 1) => {
      dispatch(
        fetchData(viewId, searchTerm, visualization === 'chart' ? 'all' : page)
      )
    },
    [viewId, searchTerm, visualization]
  )

  const handleChange = useCallback(
    debounce(async values => {
      if (deepEqual(pick(values, FIELDS), pick(view, FIELDS))) {
        return
      }

      const refetch = !deepEqual(
        pick(values, REFETCH_FIELDS),
        pick(view, REFETCH_FIELDS)
      )

      await dispatch(updateView(viewId, values))

      if (refetch) {
        handleFetch()
      }
    }, 500),
    [viewId, searchTerm, query, visualization, view]
  )

  return (
    <Form
      form={`queryForm-${viewId}`}
      onChange={handleChange}
      initialValues={view}
      view={view}
    />
  )
}
