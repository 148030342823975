import * as API from '../utils/api'
import { SWITCH_ORGANIZATION } from './users'

const FETCH = 'FETCH_INVITATION'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (
    action.type === `${FETCH}_FULFILLED` ||
    action.type === `${FETCH}_REJECTED`
  ) {
    let { invitationId } = action.meta

    let data = action.payload.response
      ? action.payload.response.data
      : action.payload.data

    return {
      ...state,
      [invitationId]: data,
    }
  }

  if (action.type === SWITCH_ORGANIZATION) {
    return INITIAL_STATE
  }

  return state
}

// Actions

export const fetchInvitation = invitationId => ({
  type: FETCH,
  meta: { invitationId },
  payload: API.fetchInvitation(invitationId),
})

// Selectors

export const getInvitation = invitationId => state => {
  return state.invitations[invitationId]
}
