import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { CardElement } from '@stripe/react-stripe-js'
import WrappedInput from '../../Shared/Forms/WrappedInput'
import FormError from '../../Shared/Forms/FormError'
import Button from '../../Shared/Button'
import styles from './UpdateCardModal.module.css'

const FORM_NAME = 'updateCardForm'

const Form = function UpdateCardForm({
  handleSubmit,
  submitting,
  error,
  subscription,
  onCancel,
}) {
  const amountDue = subscription.latestInvoice?.amount_remaining

  const buttonText =
    amountDue > 0 ? `Pay $${(amountDue / 100).toFixed(2)} Now` : 'Update'

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.cardInput}>
        <label>Card</label>
        <div className={styles.cardSub}>
          <CardElement options={{ hidePostalCode: true }} />
        </div>
      </div>
      <Field name="name" component={WrappedInput} label="Name on Card" />
      <Field
        name="address.postal_code"
        component={WrappedInput}
        label="Zip Code"
      />
      {error && <FormError>{error}</FormError>}
      <Button.Group align="right">
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary loading={submitting}>
          {buttonText}
        </Button>
      </Button.Group>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
})(Form)
