import React, { useRef, useMemo, useCallback } from 'react'
import classNames from 'classnames'
import styles from './RadioGroup.module.css'

let counter = 0

const getId = () => {
  const id = counter
  counter += 1

  return `radiogroup-${id}`
}

export default function RadioGroup({
  input,
  meta,
  options,
  label,
  allowOther,
  otherPlaceholder,
  className,
}) {
  const idRef = useRef(null)
  const { name, value, onChange } = input
  const { touched, error } = meta

  // useMemo because we want this to be synchronous
  useMemo(() => {
    idRef.current = getId()
  }, [])

  const handleChange = useCallback(e => {
    const value = e.currentTarget.value

    if (value === '_other') {
      onChange({ other: '' })
    } else {
      onChange(value)
    }
  }, [])

  const handleChangeOther = useCallback(e => {
    const value = e.currentTarget.value

    onChange({ other: value })
  }, [])

  const isOther = value && typeof value === 'object'
  const otherValue = value && value.other

  return (
    <div className={classNames(styles.wrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      {options.map(opt => (
        <div className={styles.item} key={opt.value}>
          <div className={styles.radio}>
            <input
              type="radio"
              name={name}
              className={styles.radio}
              value={opt.value}
              checked={value === opt.value}
              id={`${idRef.current}-${opt.value}`}
              onChange={handleChange}
            />
          </div>
          <label htmlFor={`${idRef.current}-${opt.value}`}>{opt.label}</label>
        </div>
      ))}
      {allowOther && (
        <div className={styles.item}>
          <div className={styles.radio}>
            <input
              type="radio"
              name={name}
              value="_other"
              checked={isOther}
              id={`${idRef.current}-other`}
              onChange={handleChange}
            />
          </div>
          <label htmlFor={`${idRef.current}-other`}>Other</label>
        </div>
      )}
      {allowOther && isOther && (
        <textarea
          autoFocus
          className={classNames(
            styles.textarea,
            touched && error && styles.textareaError
          )}
          name={`${name}-other`}
          value={otherValue}
          onChange={handleChangeOther}
          placeholder={otherPlaceholder || 'Please specify'}
        />
      )}
      {touched && error && <p className={styles.error}>{error}</p>}
    </div>
  )
}
