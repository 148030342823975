const parseNumber = (value, defaultValue = 0) => {
  if (
    Number.isNaN(value) ||
    [+Infinity, -Infinity, undefined, null].includes(value)
  ) {
    return defaultValue
  }

  if (typeof value === 'number') {
    return value
  }

  if (typeof value === 'string') {
    const cleaned = value.replace(/[^\d\.\-]/g, '')
    const parsed = parseFloat(cleaned)

    if (!Number.isNaN(parsed)) {
      return parsed
    }
  }

  return defaultValue
}

module.exports = {
  parseNumber,
}
