import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line } from 'recharts'

import { getChartData, getTicks } from '../../../../utils/charts'
import { getViewData } from '../../../../ducks/data'
import { getView } from '../../../../ducks/views'
import EmptyState from '../../../Shared/EmptyState'

import styles from './Chart.module.css'

const COLORS = ['#b10be2', '#fc0088', '#0ad3ff', '#18b728']

const Chart = function Chart({ width, height, view, data }) {
  const { data: chartData, lines, yMax } = getChartData(data)

  return (
    <LineChart
      width={width}
      height={height}
      data={chartData}
      margin={{ top: 20, right: 20, bottom: 16 }}
    >
      <XAxis dataKey="name" interval={chartData.length - 2} />
      <YAxis domain={[0, 'dataMax']} ticks={getTicks(yMax)} tickLine={false} />
      <CartesianGrid vertical={false} />
      <Tooltip />
      {lines.map((line, i) => (
        <Line
          key={line.name}
          type="monotone"
          dataKey={line.name}
          stroke={COLORS[i % COLORS.length]}
          dot={false}
          activeDot={{ r: 5 }}
          strokeWidth={2}
        />
      ))}
    </LineChart>
  )
}

export default function ChartWrapper() {
  const [[width, height], setSize] = useState([0, 0])
  const ref = useRef(null)

  const { viewId } = useParams()
  const view = useSelector(getView(viewId))
  const data = useSelector(getViewData(viewId))

  const setHeight = useCallback(el => {
    if (!el) {
      return setSize([0, 0])
    }

    const { width, height: rawHeight } = el.getBoundingClientRect()
    const height = Math.min(400, rawHeight)
    setSize([width, height])
  }, [])

  const wrapperRef = useCallback(
    el => {
      ref.current = el
      setHeight(el)
    },
    [setSize]
  )

  const handleResize = useCallback(() => {
    setHeight(ref.current)
  }, [setSize])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {width && height && data?.result ? (
        <Chart width={width} height={height} data={data} view={view} />
      ) : (
        <EmptyState loading />
      )}
    </div>
  )
}
