import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { CURRENCY_OPTIONS } from '@sqldash/common'

import WrappedInput from '../Shared/Forms/WrappedInput'
import SearchInput from '../Shared/Forms/SearchInput'
import Button from '../Shared/Button'
import Card from '../Shared/LegacyCard'
import FormError from '../Shared/Forms/FormError'
import { validateEmail } from './validations'

import styles from './Auth.module.css'

const FORM_NAME = 'signupForm'

const validate = data => {
  const errors = {}

  if (!data.email) {
    errors.email = 'Please enter your work email'
  }

  if (!data.name) {
    errors.name = 'Please enter your full name'
  }

  if (!data.password) {
    errors.password = 'Please pick a password'
  }

  if (!data.organizationName) {
    errors.organizationName = 'Please enter your company name'
  }

  return errors
}

const Form = function SignupForm({
  handleSubmit,
  submitting,
  error,
  invitation,
  allowChangeEmail,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <div className={styles.inputs}>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            component={WrappedInput}
            disabled={invitation && !allowChangeEmail}
          />
          <Field
            name="password"
            placeholder="Password"
            type="password"
            component={WrappedInput}
          />
          <Field
            name="name"
            placeholder="Your Name"
            type="text"
            component={WrappedInput}
          />
          {!invitation && (
            <>
              <Field
                name="organizationName"
                placeholder="Business Name"
                type="text"
                component={WrappedInput}
              />
              <Field
                name="website"
                placeholder="Website"
                type="text"
                component={WrappedInput}
              />
              <Field
                name="currency"
                placeholder="US Dollars ($)"
                component={SearchInput}
                options={CURRENCY_OPTIONS}
              />
            </>
          )}
        </div>
        {error && <FormError>{error}</FormError>}
        <Button block primary loading={submitting}>
          Sign Up
        </Button>
      </Card>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate: validateEmail,
})(Form)
