const { sort } = require('./arrays')
const { buildIndex } = require('./indexing')

const timeTravel = (allResources, logs, date = null) => {
  const resources = allResources.map(resource => ({ ...resource }))
  const index = buildIndex(resources, r => r.id)

  const undoMap = buildIndex(
    logs.filter(log => log.type === 'undo'),
    l => l.parentLogId
  )

  const normalLogs = logs.filter(log => log.type !== 'undo')

  const relevantLogs = (date
    ? normalLogs.filter(log => new Date(log.createdAt) > date)
    : normalLogs
  ).filter(log => !undoMap[log.id])

  for (let log of sort(relevantLogs, log => -new Date(log.createdAt))) {
    const resource = index[log.ResourceId]

    if (!resource) {
      continue
    }

    if (log.type === 'delete') {
      resource.deletedAt = null
    } else if (log.type === 'restore') {
      resource.deletedAt = new Date().toISOString() // the date doesn't matter
    } else {
      const prevStockLevel = [null, undefined].includes(log.prevStockLevel)
        ? log.finalStockLevel - log.quantity
        : log.prevStockLevel

      resource.stockLevel = prevStockLevel
    }
  }

  return resources
}

module.exports = {
  timeTravel,
}
