import moment from 'moment'

const ONE_YEAR = 365 * 24 * 60 * 60 * 1000

export const getTodayString = () => {
  return moment().format('YYYY-MM-DD')
}

window.moment = moment

export const formatDate = (dateString, long = false) => {
  const currentDate = new Date()
  const date = new Date(moment(dateString).format())
  const todayString = getTodayString()

  if (dateString === todayString) {
    return 'Today'
  }

  let format = 'll'

  if (Math.abs(currentDate - date) < ONE_YEAR) {
    format = 'MMM D'
  }

  if (long) {
    format = `dddd, ${format}`
  }

  return moment(dateString).format(format)
}

export const relativeDate = date => {
  return moment(date).fromNow()
}

export const dateInPast = date => {
  const todayString = getTodayString()

  return new Date(date) < new Date(todayString)
}
