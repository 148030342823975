const TOGGLE = Symbol('TOGGLE_ACCORDION')

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE: {
      let { group, value } = action
      let prevValue = state[group]

      if (prevValue === value) {
        value = null
      }

      return {
        ...state,
        [group]: value,
      }
    }

    default:
      return state
  }
}

// Actions

export const toggle = (group, value) => ({
  type: TOGGLE,
  group,
  value,
})

// Selectors

export const getExpanded = (groupId, id) => state => {
  return state.accordion[groupId] === id
}
