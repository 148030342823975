import pick from 'lodash/pick'
import { buildIndex } from '@sqldash/common'
import { formatValue } from './data'

const INTERVALS = [
  0.25, 1, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000,
  100000, 200000, 500000, 1000000,
]

export const getChartData = dataResult => {
  const { fields, data } = dataResult.result

  const name = fields[0].name
  const others = fields.slice(1).map(f => f.name)

  const lines = fields.slice(1)

  const result = data.map(item => ({
    ...buildIndex(
      others,
      k => k,
      k => +item[k] || 0
    ),
    name: formatValue(fields[0], item[name]),
  }))

  let yMax = 0

  for (const item of result) {
    const max = Math.max(...Object.values(pick(item, others)))

    if (max > yMax) {
      yMax = max
    }
  }

  return {
    data: result,
    lines,
    yMax,
  }
}

export const getTicks = max => {
  let interval = INTERVALS[0]

  for (const intrvl of INTERVALS) {
    interval = intrvl
    const tickCount = Math.floor(max / interval)

    if (tickCount < 5) {
      break
    }
  }

  const ticks = []

  for (let i = 0; i <= Math.floor(max / interval); i += 1) {
    ticks.push(i * interval)
  }

  return ticks
}
