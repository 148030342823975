import flatten from 'lodash/flatten'
import { buildIndex, sort } from '@sqldash/common'
import * as API from '../utils/api'
import { getCurrentOrganizationId, FETCH_CURRENT_USER } from './users'

const FETCH_DETAILS = 'FETCH_DATABASE_DETAILS'
const CREATE = 'CREATE_DATABASE'
const UPDATE = 'UPDATE_DATABASE'
const DELETE = 'DELETE_DATABASE'

const INITIAL_STATE = {}

// Reducer

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_CURRENT_USER}_FULFILLED`: {
      const databases = flatten(
        action.payload.data.Organizations.map(org => org.Databases)
      )

      return buildIndex(databases, db => db.id)
    }

    case `${FETCH_DETAILS}_FULFILLED`:
    case `${CREATE}_FULFILLED`:
    case `${UPDATE}_FULFILLED`: {
      const data = action.payload.data

      return {
        ...state,
        [data.id]: data,
      }
    }

    case `${DELETE}_FULFILLED`: {
      const { id } = action.meta

      return {
        ...state,
        [id]: undefined,
      }
    }

    default:
      return state
  }
}

// Actions

export const fetchDatabase = orgId => ({
  type: FETCH_DETAILS,
  payload: API.fetchDatabase(orgId),
})

export const createDatabase = (orgId, data) => ({
  type: CREATE,
  payload: API.createDatabase(orgId, data),
})

export const updateDatabase = (id, data) => ({
  type: UPDATE,
  payload: API.updateDatabase(id, data),
})

export const deleteDatabase = id => ({
  type: DELETE,
  payload: API.deleteDatabase(id),
  meta: { id },
})

// Selectors

export const getDatabases = state => {
  const orgId = getCurrentOrganizationId(state)

  return sort(
    Object.values(state.databases).filter(db => db.OrganizationId === orgId),
    db => db.name
  )
}

export const getDatabase = id => state => {
  return state.databases[id]
}
