import React, { useCallback, useState } from 'react'
import { SubmissionError } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentOrganizationId } from '../../../ducks/users'
import { updateOrganization } from '../../../ducks/organizations'
import CenteredModal from '../../Shared/CenteredModal'
import Button from '../../Shared/Button'
import Form from './Form'
import styles from './ShopifyUpgradeFeedback.module.css'

const LABELS = {
  squarespace: 'Squarespace',
  etsy: 'Etsy',
  wix: 'Wix',
  wordpress: 'WooCommerce',
  bigcommerce: 'Big Commerce',
}

export default function UpgradeFeedback({ match }) {
  const [submission, setSubmission] = useState(null)
  const dispatch = useDispatch()
  const orgId = useSelector(getCurrentOrganizationId)

  const handleBack = useCallback(() => {
    window.history.back()
  }, [])

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(updateOrganization(orgId, { surveyResults: values }))
        setSubmission(values.shopHost)
      } catch (err) {
        throw new SubmissionError({ _error: 'An expected error occurred' })
      }
    },
    [dispatch, orgId, handleBack]
  )

  const label = LABELS[submission]

  return (
    <CenteredModal>
      {submission ? (
        <div className={styles.success}>
          <h1>Thanks!</h1>
          {label ? (
            <p>
              We’ll leave you alone until {label} is available{' '}
              <span className={styles.emoji}>😉</span>
            </p>
          ) : (
            <p>
              Your feedback helps us make Inventora better for everyone{' '}
              <span className={styles.emoji}>🙂</span>
            </p>
          )}
          <Button onClick={handleBack}>Done</Button>
        </div>
      ) : (
        <Form onSubmit={handleSubmit} onCancel={handleBack} />
      )}
    </CenteredModal>
  )
}
