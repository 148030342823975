import React, { useMemo, useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import Highlighter from 'react-highlight-words'

import { useSearchTerm } from '../../../utils/hooks'
import { formatValue } from '../../../utils/data'
import { updateView } from '../../../ducks/views'
import EmptyState from '../../Shared/EmptyState'
import Table from '../../Shared/Table'
import styles from './Table.module.css'

const Cell = function Cell({ row, field }) {
  const value = row[field.name]
  const type = field.type
  const searchTerm = useSearchTerm()

  const formattedValue = useMemo(() => {
    return formatValue(field, value)
  }, [value, field])

  if (value === null) {
    return <span className={styles.null}>null</span>
  }

  const isJSON = ['json', 'jsonb'].includes(type)

  const content = searchTerm ? (
    <Highlighter
      autoEscape
      highlightClassName={styles.highlight}
      searchWords={[searchTerm]}
      textToHighlight={formattedValue}
    />
  ) : (
    formattedValue
  )

  return formattedValue.match(/^https?:\/\//) ? (
    <a
      className={styles.link}
      target="_blank"
      rel="nofollow noreferrer"
      href={formattedValue}
    >
      {content}
    </a>
  ) : (
    <span className={classNames(isJSON && styles.json)}>{content}</span>
  )
}

export default function DataTable({ view, result, loading }) {
  const dispatch = useDispatch()

  const handleResize = useCallback(
    async (columnName, width) => {
      await dispatch(
        updateView(view.id, {
          ...view,
          columnWidths: {
            ...view.columnWidths,
            [columnName]: width,
          },
        })
      )
    },
    [view]
  )

  if (!result) {
    return <EmptyState loading />
  }

  const { data } = result

  const columns = result.fields
    .filter(f => f.name !== '__color')
    .map(field => ({
      name: field.name,
      renderCell: ({ row }) => <Cell row={row} field={field} />,
      width: view.columnWidths?.[field.name],
    }))

  const getRowColor = useCallback(row => {
    return row.__color
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <Table
          columns={columns}
          data={data}
          onResizeColumn={handleResize}
          getRowColor={getRowColor}
        />
        {data.length === 0 && !loading && (
          <EmptyState>
            <h2>No data</h2>
          </EmptyState>
        )}
        {loading && <EmptyState loading />}
      </div>
    </div>
  )
}
