import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { useHistory, Link } from 'react-router-dom'

import { getRedirectURL } from '../../utils/auth'
import { login } from '../../ducks/users'
import AuthPage from '../Shared/AuthPage'
import LoginForm from './LoginForm'

export default function Login() {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogin = useCallback(
    async data => {
      try {
        await dispatch(login(data))
        history.push(getRedirectURL())
      } catch (err) {
        throw new SubmissionError({
          _error: 'Your email / password combination is not correct',
        })
      }
    },
    [dispatch, history]
  )

  const signupURL = `/signup${window.location.search}`

  return (
    <AuthPage
      links={
        <>
          <p>
            No Account? <Link to={signupURL}>Sign Up</Link>
          </p>
          <p>
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </>
      }
    >
      <LoginForm onSubmit={handleLogin} />
    </AuthPage>
  )
}
