import React, { useRef, useCallback } from 'react'

const suppress = e => {
  e.stopPropagation()
}

const suppressNative = e => {
  e.pleaseIgnore = true
}

export default function EventSupressor({ children, ...props }) {
  const ref = useRef()

  const handleRef = useCallback(el => {
    if (ref.current === el) {
      return
    }

    if (ref.current) {
      ref.current.removeEventListener('click', suppressNative)
      ref.current.removeEventListener('mousedown', suppressNative)
      ref.current.removeEventListener('mouseup', suppressNative)
    }

    if (el) {
      el.addEventListener('click', suppressNative)
      el.addEventListener('mousedown', suppressNative)
      el.addEventListener('mouseup', suppressNative)
    }
  }, [])

  return (
    <div
      {...props}
      ref={handleRef}
      onClick={suppress}
      onMouseDown={suppress}
      onMouseUp={suppress}
    >
      {children}
    </div>
  )
}
