import React, { useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getViewData } from '../../../ducks/data'
import styles from './Pagination.module.css'

export default function Pagination() {
  const history = useHistory()
  const { viewId } = useParams()
  const data = useSelector(getViewData(viewId))

  const { page, pageSize, totalResults } = data?.result?.pagination || {}

  const start = (+page - 1) * pageSize + 1
  const end = Math.min(start + pageSize - 1, totalResults)
  const lastPage = Math.ceil((totalResults || 0) / pageSize)

  const handlePrev = useCallback(() => {
    history.replace(`/views/${viewId}?page=${+page - 1}`)
  }, [page, viewId, history])

  const handleNext = useCallback(() => {
    history.replace(`/views/${viewId}?page=${+page + 1}`)
  }, [page, viewId, history])

  if (!data?.result?.pagination) return null

  return (
    <div className={styles.wrapper}>
      <button className={styles.prev} onClick={handlePrev} disabled={page <= 1}>
        «
      </button>
      <span className={styles.data}>
        {start} - {end} of {totalResults}
      </span>
      <button
        className={styles.next}
        onClick={handleNext}
        disabled={lastPage <= page}
      >
        »
      </button>
    </div>
  )
}
