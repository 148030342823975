class ValidationError extends Error {
  constructor(message, type = null) {
    super(message)

    if (type) {
      this._type = type
    }
  }
}

module.exports = {
  ValidationError,
}
