import React, { useEffect } from 'react'
import classNames from 'classnames'

import styles from './AuthPage.module.css'

export default function AuthPage({ children, links, title, wide }) {
  useEffect(() => {
    document.body.classList.add(styles.body)
    document.documentElement.classList.add(styles.document)

    return () => {
      document.body.classList.remove(styles.body)
      document.documentElement.classList.remove(styles.document)
    }
  }, [])

  return (
    <div className={classNames(styles.wrapper, wide && styles.wide)}>
      <div className={styles.brand}>SQLDash</div>
      {title && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.children}>{children}</div>
      <div className={styles.links}>{links}</div>
    </div>
  )
}

export const Sidebar = function Sidebar({ children }) {
  return <div className={styles.sidebar}>{children}</div>
}
