import React from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import { CREDENTIAL_FIELDS, DATABASE_TYPES } from '@sqldash/common'

import WrappedInput from '../../../Shared/Forms/WrappedInput'
import FormError from '../../../Shared/Forms/FormError'
import Button from '../../../Shared/Button'

const FORM_NAME = 'createDatabase'

const validate = values => {
  let errors = {}

  if (!values.email) {
    errors.email = 'Please enter an email.'
  }

  return errors
}

const Form = function InviteForm({
  handleSubmit,
  submitting,
  onClose,
  error,
  form,
}) {
  const selector = formValueSelector(form)
  const type = useSelector(state => selector(state, 'type'))

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="name"
        component={WrappedInput}
        label="Nickname"
        placeholder="My Database"
        autoComplete="off"
      />
      <Field
        component={WrappedInput}
        name="type"
        options={DATABASE_TYPES}
        label="Database Type"
        placeholder="Select Type"
      />
      {type && (
        <>
          {CREDENTIAL_FIELDS[type].map(field => (
            <Field
              {...field}
              key={field.name}
              component={WrappedInput}
              autoComplete="off"
              name={`credentials.${field.name}`}
            />
          ))}
        </>
      )}
      {error && <FormError>{error}</FormError>}
      <Button.Group align="right">
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button primary loading={submitting}>
          Create
        </Button>
      </Button.Group>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(Form)
