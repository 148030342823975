import { matchSearchTerm } from '@sqldash/common'

export const assignDepth = (items, depth = 0) => {
  for (const item of items) {
    item.depth = depth

    if (item.children?.length > 0) {
      assignDepth(item.children, depth + 1)
    }
  }
}

export const filterFolders = (items, searchTerm) => {
  if (!searchTerm) return [items, []]

  const results = []
  const forceExpand = []

  for (const item of items) {
    const matches = matchSearchTerm(item.data.name || 'Untitled', searchTerm)

    const matchingChildrenResult =
      item.children && filterFolders(item.children, searchTerm)

    forceExpand.push(...(matchingChildrenResult?.[1] || []))

    const matchingChildren = matchingChildrenResult?.[0]

    if (matches) {
      results.push(item)
    } else if (matchingChildren?.length > 0) {
      forceExpand.push(item.id)

      results.push({
        ...item,
        children: matchingChildren,
      })
    }
  }

  return [results, forceExpand]
}
