import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'

import store from './redux-store'
import App from './components/App'

// Polyfills
import 'es7-object-polyfill'
import '@inventora/better-dateinput-polyfill/dist/better-dateinput-polyfill'

// Initialize Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9c7dfee1ac744ce39cb6a62502418699@o566117.ingest.sentry.io/5708510',
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
