import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import {
  changeOrganization,
  getOrganizations,
  getCurrentOrganization,
} from '../../../../ducks/users'

import WrappedInput from '../../Forms/WrappedInput'

const FORM_NAME = 'switchOrganizationForm'

const NakedForm = function SwitchOrganizationForm({ organizations }) {
  const options = organizations.map(org => ({
    label: org.name,
    value: org.id,
  }))

  return (
    <Field
      noPad
      name="id"
      component={WrappedInput}
      options={options}
      label="Team"
    />
  )
}

const Form = reduxForm({
  form: FORM_NAME,
})(NakedForm)

export default function SwitchOrganization({ onChange }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const organizations = useSelector(getOrganizations)
  const currentOrg = useSelector(getCurrentOrganization)

  const handleChange = async ({ id }) => {
    if (id !== (currentOrg && currentOrg.id)) {
      await dispatch(changeOrganization(id))

      history.push('/')

      if (onChange) {
        window.setTimeout(onChange, 0)
      }
    }
  }

  const initialValues = { id: currentOrg && currentOrg.id }

  return (
    <Form
      onChange={handleChange}
      initialValues={initialValues}
      organizations={organizations}
    />
  )
}
