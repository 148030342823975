import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  createContext,
} from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import EventSuppressor from '../EventSuppressor'

import styles from './Menu.module.css'

export const MenuContext = createContext({ open: false })

export default function MenuTrigger({
  className,
  activeClassName,
  options,
  children,
  noPortal,
  align = 'left',
}) {
  const [open, setOpen] = useState(false)

  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  })

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleOpen = useCallback(
    e => {
      setOpen(true)
      const el = e.currentTarget
      const rect = el.getBoundingClientRect()

      setPosition({
        top: Math.round(rect.bottom),
        bottom: Math.round(window.innerHeight - rect.top),
        left: Math.round(rect.left),
        right: Math.round(window.innerWidth - rect.right),
      })
    },
    [setOpen, setPosition]
  )

  return (
    <EventSuppressor
      className={classNames(styles.wrapper, className, open && activeClassName)}
    >
      <MenuContext.Provider value={{ open }}>
        <div className={styles.trigger} onClick={handleOpen}>
          {children}
        </div>
      </MenuContext.Provider>
      {open && (
        <Menu
          options={options}
          position={position}
          align={align}
          onClose={handleClose}
          noPortal={noPortal}
        />
      )}
    </EventSuppressor>
  )
}

const MenuOption = function MenuOption({ option, onClose }) {
  const { to, onClick, label, disabled, className } = option
  const Component = to && !disabled ? Link : 'div'

  const handleClick = useCallback(() => {
    if (onClick) {
      window.setTimeout(() => onClick(), 10)
      onClose()
    }

    if (to) {
      window.setTimeout(() => onClose(), 10)
    }
  }, [onClose])

  const props = disabled ? {} : { to, onClick: handleClick }

  return (
    <Component
      {...props}
      className={classNames(
        styles.option,
        disabled && styles.disabled,
        className
      )}
    >
      {label || 'Untitled'}
    </Component>
  )
}

const Menu = function Menu({ options, position, align, onClose, noPortal }) {
  const wrapper = useRef(null)

  useEffect(() => {
    // Add an element for the menu to go inside
    return () => {
      if (wrapper.current) {
        document.body.removeChild(wrapper.current)
      }
    }
  }, [])

  const getWrapper = useCallback(() => {
    if (!wrapper.current) {
      const el = document.createElement('div')
      el.className = styles.menuWrapper
      document.body.appendChild(el)
      wrapper.current = el
    }

    return wrapper.current
  }, [])

  const menuStyles = {}

  if (position.top <= position.bottom) {
    menuStyles.top = position.top + 4
  } else {
    menuStyles.bottom = position.bottom + 8
  }

  if (align === 'left') {
    menuStyles.left = position.left
  } else if (align === 'right') {
    menuStyles.right = position.right
  }

  const content = (
    <>
      <div className={styles.backdrop} onMouseDown={onClose} />
      <div className={styles.menu} style={menuStyles}>
        {options.map((opt, i) =>
          opt === null ? (
            // eslint-disable-next-line react/no-array-index-key
            <span className={styles.divider} key={i} />
          ) : (
            <MenuOption
              key={opt.key || `${opt.label}-${i}`}
              option={opt}
              onClose={onClose}
            />
          )
        )}
      </div>
    </>
  )

  if (noPortal) {
    return content
  }

  return ReactDOM.createPortal(content, getWrapper())
}
