import React from 'react'
import { reduxForm, Field } from 'redux-form'

import Header from '../Shared/Header'
import Content from '../Shared/Content'
import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import FormError from '../Shared/Forms/FormError'

export const FORM_NAME = 'accountSettings'

const validate = values => {
  const errors = {}
  const { oldPassword, password, name, email } = values

  if (!name) {
    errors.name = 'Please enter your name'
  }

  if (!email) {
    errors.email = 'Please enter your email'
  }

  if (oldPassword && !password) {
    errors.password = 'Please enter a new password'
  }

  if (password && !oldPassword) {
    errors.oldPassword = 'Please enter your current password'
  }

  if (password && oldPassword && password === oldPassword) {
    errors.password = 'Your new password is the same as your old password'
  }

  return errors
}

const Form = function AccountForm({
  handleSubmit,
  submitting,
  pristine,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Header
        actions={
          <Button primary disabled={pristine} loading={submitting}>
            Save Profile
          </Button>
        }
      >
        My Profile
      </Header>
      <Content>
        <Field component={WrappedInput} name="name" label="Name" />
        <Field component={WrappedInput} name="email" label="Email" />
        <Field
          component={WrappedInput}
          name="oldPassword"
          label="Old Password"
          type="password"
        />
        <Field
          component={WrappedInput}
          name="password"
          label="Password"
          type="password"
        />
        {error && <FormError>{error}</FormError>}
      </Content>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate,
})(Form)
