const CURRENCIES = require('./currency-resources/currencies.json')

const CURRENCY_OPTIONS = Object.values(CURRENCIES).map(currency => ({
  label: `${currency.name} (${currency.symbol})`,
  value: currency.code,
}))

const getCurrency = organization => {
  if (!organization) {
    return null
  }

  return CURRENCIES[organization.currency] || CURRENCIES.USD
}

const getCurrencySymbol = organization => {
  const currency = getCurrency(organization)

  return currency.symbol_native
}

module.exports = {
  CURRENCY_OPTIONS,
  getCurrency,
  getCurrencySymbol,
}
