import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentOrganizationId, getCurrentUser } from '../../ducks/users'

import { FullScreenLoader } from '../Shared/Loading'
import Header from '../Shared/Header'
import Content from '../Shared/Content'
import Info from './Info'
import Users from './Users'
import Billing from './Billing'
import Databases from './Databases'

export default function Settings() {
  const organizationId = useSelector(getCurrentOrganizationId)
  const user = useSelector(getCurrentUser)

  if (!user) {
    return <FullScreenLoader />
  }

  return (
    <div>
      <Header>Settings</Header>
      <Content>
        <Info organizationId={organizationId} />
        <Users organizationId={organizationId} />
        <Billing organizationId={organizationId} />
        <Databases />
      </Content>
    </div>
  )
}
