import React from 'react'
import CenteredModal from '../../Shared/CenteredModal'
import Button from '../../Shared/Button'

import styles from './UpgradeSuccess.module.css'
import CongratsEmoji from './congrats-emoji.png'

export default function UpgradeSuccess({ match }) {
  const urlMatch = match.url.match(/^(.*?)\/upgrade-success\/?$/)
  const doneURL = urlMatch && urlMatch[1]

  return (
    <CenteredModal>
      <div className={styles.wrapper}>
        <img src={CongratsEmoji} width={64} height={64} alt="congrats" />
        <div className={styles.content}>
          <h1>Welcome to Inventora Business!</h1>
          <p>
            You’ll now have access to all the features to
            <br className="hidden-sm" /> manage your inventory like a pro.
          </p>
        </div>
        <div className={styles.actions}>
          <Button replace to={doneURL}>
            Done
          </Button>
        </div>
      </div>
    </CenteredModal>
  )
}
