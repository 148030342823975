import { useEffect } from 'react'
import QS from 'qs'
import { useSelector } from 'react-redux'

import { convert } from '../../../utils/analytics'
import { getCurrentUser } from '../../../ducks/users'

export default function UpgradeWatcher() {
  const query = window.location.search
  const user = useSelector(getCurrentUser)

  useEffect(() => {
    const query = QS.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (user && query.upgrade === 'successful') {
      convert(user)
    }
  }, [query, user])

  return null
}
