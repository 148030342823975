const { sort } = require('./arrays')

const COLORS = [
  '#e8a2a2',
  '#e8cca2',
  '#fbf0b7',
  '#c9e8a2',
  '#a2e8c6',
  '#a2e3e8',
  '#a2bee8',
  '#b8a2e8',
  '#e8a2d8',
  '#f9dde3',
]

const getCategoryColor = categories => {
  let sorted = sort(COLORS, color => {
    return categories.filter(c => c.color === color).length
  })

  return sorted[0]
}

module.exports = {
  getCategoryColor,
  COLORS,
}
