import React from 'react'

import styles from './Header.module.css'

export default function Header({
  text,
  subtitle,
  children,
  component = 'h1',
  actions,
}) {
  const Component = component || 'h1'

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Component className={styles.header}>
            {text || children}
            <span className={styles.placeholder}>-</span>
          </Component>
          {subtitle && <p>{subtitle}</p>}
        </div>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
      <div className={styles.spacer} />
    </>
  )
}
