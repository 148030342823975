import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { processError } from '../../../utils/errors'
import { goBack } from '../../../utils/history'
import { getFolder, updateFolder } from '../../../ducks/views'
import CenteredModal from '../../Shared/CenteredModal'
import EmptyState from '../../Shared/EmptyState'
import Form from './Form'

export default function CreateFolder() {
  const dispatch = useDispatch()
  const { folderId } = useParams()
  const folder = useSelector(getFolder(folderId))

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(updateFolder(folderId, values))
        goBack()
      } catch (err) {
        processError(err)
      }
    },
    [folderId]
  )

  return (
    <CenteredModal>
      <CenteredModal.Header>Edit Folder</CenteredModal.Header>
      {folder ? (
        <Form initialValues={folder} onSubmit={handleSubmit} />
      ) : (
        <EmptyState loading />
      )}
    </CenteredModal>
  )
}
