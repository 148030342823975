import QS from 'qs'

export const getRedirectURL = () => {
  const { redirect, plan } = QS.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  if (plan && window.location.pathname === '/signup') {
    return `/subscribe?${QS.stringify({ plan })}`
  }

  return redirect || '/'
}
