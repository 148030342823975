import data from 'emoji-picker-react/src/data/emojis.json'
import { buildIndex } from '@sqldash/common'

const index = buildIndex(Object.values(data).flat(), itm => itm.n[0])

export const getUnifiedEmoji = name => {
  const item = index[name]

  return item?.u
}
