export const getDropTarget = (items, activeDrag) => {
  const expanded = getExpandedItems(items)
  const { currentPoint, offsetTop, item } = activeDrag

  const position = (currentPoint[1] - offsetTop) / 30

  const initialTarget = expanded[Math.floor(position)]
  const isFolder = initialTarget?.data.__isFolder__

  const dropInside =
    isFolder &&
    position > 0 &&
    position < expanded.length &&
    position % 1 >= 0.25 &&
    position % 1 <= 0.75

  const dropPosition = Math.max(
    0,
    Math.min(
      expanded.length,
      dropInside ? Math.floor(position) : Math.round(position)
    )
  )

  const dropTarget = expanded[dropPosition]
  const prevItem = expanded[dropPosition - 1]

  const order = getNewOrder(item, prevItem, dropTarget, dropInside)

  return {
    dropTarget,
    order,
    dropPosition,
    dropInside,
    folderId: (dropInside ? dropTarget?.id : dropTarget?.data.FolderId) || null,
  }
}

export const getExpandedItems = items => {
  const results = []

  for (const item of items) {
    results.push(item)

    if (item.expanded && item.children?.length > 0) {
      results.push(...getExpandedItems(item.children))
    }
  }

  return results
}

const getNewOrder = (item, prev, target, dropInside) => {
  if (!prev && !target) {
    return 0
  }

  if (target && dropInside) {
    if (target.id === item.data.FolderId) {
      return item.data.order
    }

    const firstItem = target.children?.[0]

    return firstItem?.data.order - 1 || 0
  }

  if (!prev) {
    return target.data.order - 1
  }

  if (!target) {
    return prev.data.order + 1
  }

  if (prev.depth === target.depth) {
    return (prev.data.order + target.data.order) / 2
  }

  if (prev.depth < target.depth) {
    return target.data.order - 1
  }

  if (prev.depth > target.depth) {
    const prevAtDepth = getParentAtDepth(prev, target.depth)

    if (!prevAtDepth) {
      return target.data.order - 1
    }

    return (prevAtDepth.data.order + target.data.order) / 2
  }

  return 0
}

const getParentAtDepth = (item, depth) => {
  if (!item || item.depth === depth) {
    return item
  }

  return getParentAtDepth(item.parentFolder, depth)
}
