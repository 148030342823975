const deepEqual = require('deep-equal')
const { buildIndex } = require('./indexing')

const diffMetadata = (oldMetadata, newMetadata) => {
  const result = {}

  oldMetadata = oldMetadata || {}
  newMetadata = newMetadata || {}

  const keys = Object.keys({ ...oldMetadata, ...newMetadata }).filter(
    key => oldMetadata[key] || newMetadata[key]
  )

  for (let key of keys) {
    if (newMetadata[key] && oldMetadata[key] !== newMetadata[key]) {
      result[key] = newMetadata[key]
    }
  }

  return result
}

// A - old
// B - new
const diffArrays = (a, b, keyGetter, serialize) => {
  if (!keyGetter) {
    keyGetter = itm => itm
  }

  if (!serialize) {
    serialize = itm => itm
  }

  const aIndex = buildIndex(a, keyGetter)
  const bIndex = buildIndex(b, keyGetter)
  const union = Object.keys({ ...aIndex, ...bIndex })

  const added = []
  const removed = []
  const updated = []

  for (const key of union) {
    if (!aIndex[key]) {
      added.push(bIndex[key])
    } else if (!bIndex[key]) {
      removed.push(aIndex[key])
    } else if (!deepEqual(serialize(aIndex[key]), serialize(bIndex[key]))) {
      updated.push(bIndex[key])
    }
  }

  return [added, removed, updated]
}
module.exports = {
  diffMetadata,
  diffArrays,
}
