const { buildIndex } = require('./indexing')

const DATABASE_TYPES = [
  { label: 'Postgres', value: 'postgres' },
  { label: 'MySQL', value: 'mysql' },
]

const LABELS = buildIndex(
  DATABASE_TYPES,
  db => db.value,
  db => db.label
)

const CREDENTIAL_FIELDS = {
  postgres: [
    { label: 'Host', name: 'host', placeholder: 'localhost' },
    { label: 'Port', name: 'port', placeholder: '5432' },
    { label: 'Database', name: 'database', placeholder: 'postgres' },
    { label: 'Username', name: 'user', placeholder: 'postgres' },
    { label: 'Password', name: 'password', placeholder: 'postgres' },
  ],
  mysql: [
    { label: 'Host', name: 'host', placeholder: 'localhost' },
    { label: 'Port', name: 'port', placeholder: '3306' },
    { label: 'Database', name: 'database', placeholder: 'mysql' },
    { label: 'Username', name: 'username', placeholder: 'root' },
    { label: 'Password', name: 'password', placeholder: 'root' },
  ],
}

module.exports = {
  DATABASE_TYPES,
  LABELS,
  CREDENTIAL_FIELDS,
}
