import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import QS from 'qs'
import DocumentEvents from 'react-document-events'
import { useHistory } from 'react-router-dom'

import { useSearchTerm } from '../../../utils/hooks'
import { search, getSearchTerm } from '../../../ducks/search'
import Icon from '../Icon'

import styles from './Search.module.css'

export const SearchInput = function Search({
  value,
  onChange,
  onClear,
  placeholder = 'Search',
  commandKey = 'f',
  underline,
  className,
  inputRef: passedInputRef,
  ...props
}) {
  const inputRef = useRef()
  const searchTerm = value

  const handleSearch = useCallback(
    e => {
      const value = e.currentTarget.value
      const normalized = value
      onChange(normalized)
    },
    [onChange]
  )

  const focus = () => {
    if (!inputRef.current) return

    inputRef.current.focus()
    inputRef.current.select()
  }

  const handleClear = useCallback(
    e => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      onChange('')
      focus()

      if (onClear) window.setTimeout(() => onClear(), 0)
    },
    [onChange, window.location.pathname, onClear]
  )

  const handleKeyPress = useCallback(e => {
    if (inputRef.current && e.key === commandKey && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      focus()
    }
  }, [])

  const refWrapper = el => {
    inputRef.current = el
    if (passedInputRef) passedInputRef.current = el
  }

  return (
    <div
      className={classNames(
        styles.search,
        underline && styles.underline,
        className
      )}
    >
      <DocumentEvents onKeyDown={handleKeyPress} />
      <div className={styles.icon}>
        <Icon icon="search" color="grey" />
      </div>
      <div className={styles.inputWrapper}>
        <input
          {...props}
          ref={refWrapper}
          type="text"
          value={searchTerm || ''}
          onChange={handleSearch}
          placeholder={placeholder}
        />
      </div>
      {searchTerm && (
        <div
          className={styles.x}
          onMouseDown={handleClear}
          onTouchStart={handleClear}
        >
          <Icon color="grey" icon="x-circle" />
        </div>
      )}
    </div>
  )
}

const QueryStringSearch = function QueryStringSearch({ ...props }) {
  const urlValue = useSearchTerm()
  const [value, setValue] = useState(urlValue || '')
  const history = useHistory()

  const handleChangeURL = useCallback(value => {
    const params = QS.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })

    params.q = value || undefined

    if (params.page) delete params.page

    const path = history.location.pathname

    history.replace(`${path}?${QS.stringify(params)}`)
  }, [])

  const handleKeyDown = useCallback(
    e => {
      if (e.which === 13) {
        e.preventDefault()
        handleChangeURL(value)
      }
    },
    [handleChangeURL, value]
  )

  const handleClear = useCallback(() => {
    handleChangeURL('')
  }, [handleChangeURL])

  return (
    <SearchInput
      {...props}
      value={value}
      onChange={setValue}
      onKeyDown={handleKeyDown}
      onClear={handleClear}
    />
  )
}

export const ReduxSearch = function ReduxSearch({ name, ...props }) {
  const dispatch = useDispatch()
  const value = useSelector(getSearchTerm(name))

  const handleChange = useCallback(value => {
    dispatch(search(name, value))
  }, [])

  return <SearchInput {...props} value={value} onChange={handleChange} />
}

export default QueryStringSearch
