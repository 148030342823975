import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './Login'
import Signup from './Signup'
import AcceptInvitation from './AcceptInvitation'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'

export const AUTH_ROUTES = [
  '/login',
  '/signup',
  '/invitations',
  '/forgot-password',
  '/reset-password',
  '/auth',
]

export default function Auth() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/invitations/:invitationId" component={AcceptInvitation} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:token" component={ResetPassword} />
    </Switch>
  )
}
