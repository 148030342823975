import React, { useCallback } from 'react'
import classNames from 'classnames'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import styles from './SearchInput.module.css'

let portalTarget = null

const getPortalTarget = () => {
  if (!portalTarget) {
    portalTarget = document.createElement('div')
    portalTarget.style.position = 'fixed'
    portalTarget.style.left = 0
    portalTarget.style.top = 0
    portalTarget.style.zIndex = 10001
    portalTarget.className = styles.portalContainer
    document.body.appendChild(portalTarget)
  }

  return portalTarget
}

// Useful for debugging CSS:
// eslint-disable-next-line no-unused-vars
const log = (name, func) => (provided, state, props) => {
  return func ? func(provided, state) : provided
}

const customStyles = (error, { noBorder, noCarret, tall }) => {
  return {
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: 10,
      borderColor: error
        ? 'var(--red) !important'
        : isFocused
        ? 'var(--hairlineDark) !important'
        : 'var(--hairline) !important',
      borderWidth: noBorder ? 0 : 1,
      boxShadow: 'none',
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: noBorder ? 0 : 13,
      height: tall ? 60 : 45,
      cursor: 'text',
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: 15,
      color: 'var(--placeholder)',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: 15,
    }),
    multiValue: provided => ({
      ...provided,
      background: 'transparent',
    }),
    input: provided => ({
      ...provided,
      font: 'inherit',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValueRemove: provided => ({
      ...provided,
      cursor: 'pointer',
      background: 'transparent',
      '&:hover': {
        color: '#000',
        background: 'rgba(0, 0, 0, 0.1)',
      },
    }),
    option: provided => ({
      ...provided,
      color: 'var(--black)',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: noCarret ? 'none' : provided.display,
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: 10000,
      position: 'fixed',
    }),
  }
}

const getSelectTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      danger: 'var(--red)',
      dangerLight: 'var(--redAlpha20Opaque)',

      /*
       * control/boxShadow(focused)
       * control/borderColor(focused)
       * control/borderColor:hover(focused)
       * option/backgroundColor(selected)
       * option/backgroundColor:active(selected)
       */
      primary: 'var(--hairline)',

      /*
       * option/backgroundColor(focused)
       */
      primary25: 'var(--lightestGrey)',

      /*
       * option/backgroundColor:active
       */
      primary50: 'var(--hairline)',
      primary75: 'var(--hairline)',
    },
  }
}

export const MultiValueContainer = function MultiValueContainer({
  data,
  ...props
}) {
  const customStyles = {}

  if (data && data.color) {
    customStyles.background = data.color
  }

  return (
    <div className={styles.multiValueContainer} style={customStyles}>
      <components.MultiValueContainer data={data} {...props} />
    </div>
  )
}

export default function SearchInput({
  label,
  options,
  input,
  meta,
  isCreatable,
  hideNoOptions,
  noPad,
  withPortal,
  noBorder,
  noCarret,
  tall,
  disabled,
  components: componentsProp,
  ...props
}) {
  const handleBlur = useCallback(() => {
    // Do nothing
    // Note: we call input.onBlur in handleChange instead, because when
    // calling it here, we did not always have the value to pass in.
  }, [])

  const { error: hasError, touched } = meta
  const error = touched ? hasError : undefined

  const handleChange = useCallback(
    option => {
      let value

      if (Array.isArray(option)) {
        value = option.map(opt => {
          if (opt.__isNew__) {
            return opt
          }

          return opt.value
        })
      } else if (option?.__isNew__) {
        value = option
      } else {
        value = option?.value || null
      }

      input.onChange(value)
      input.onBlur(value)
    },
    [input]
  )

  const flattenOptions = options => {
    let results = [...options]

    for (let opt of options) {
      if (opt.options) {
        results = results.concat(opt.options)
      }
    }

    return results
  }

  const formatValue = value => {
    if (value.__isNew__) {
      return value
    }

    const result = flattenOptions(options).find(opt => opt.value === value)

    if (!result) {
      return null
    }

    return result
  }

  const value = Array.isArray(input.value)
    ? input.value.map(formatValue)
    : formatValue(input.value)

  const SelectComponent = isCreatable ? CreatableSelect : Select

  let portalTarget

  if (withPortal) {
    portalTarget = getPortalTarget()
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        noPad && styles.noPad,
        disabled && styles.disabled
      )}
    >
      <label>{label}</label>
      <div className={styles.input}>
        <SelectComponent
          {...props}
          value={value}
          onChange={handleChange}
          onFocus={input.onFocus}
          onBlur={handleBlur}
          options={options}
          styles={customStyles(error, { noBorder, noCarret, tall })}
          components={{ MultiValueContainer, ...componentsProp }}
          noOptionsMessage={() => (hideNoOptions ? null : undefined)}
          theme={getSelectTheme}
          menuPortalTarget={portalTarget}
        />
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  )
}
