import memoize from 'lodash/memoize'
import throttle from 'lodash/throttle'
import * as API from '../../utils/api'

const TEN_MINUTES = 10 * 60 * 1000

const emailAvailable = async email => {
  let result = await API.validateEmail(email)

  return result.data.available
}

// Returns an email validator func with a 10 minute TTL
// After 10 minutes, cache will be cleared
const getCheckerFunc = throttle(
  () => {
    return memoize(emailAvailable, email => email)
  },
  TEN_MINUTES,
  { leading: true }
)

export const validateEmail = async values => {
  const func = getCheckerFunc()

  const available = await func(values.email)

  if (!available) {
    throw {
      email: 'This email is already in use',
    }
  }
}
