import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Elements, CardElement, useElements } from '@stripe/react-stripe-js'
import { getStripe } from '../../../utils/stripe'
import { processError } from '../../../utils/errors'

import {
  fetchSubscription,
  getSubscription,
  updateSubscription,
} from '../../../ducks/subscriptions'

import { getCurrentOrganization, fetchCurrentUser } from '../../../ducks/users'
import { goBack } from '../../../utils/history'
import EmptyState from '../../Shared/EmptyState'
import CenteredModal from '../../Shared/CenteredModal'
import Form from './Form'

const Modal = function UpdateCardModal() {
  const stripe = getStripe()
  const org = useSelector(getCurrentOrganization)
  const subscription = useSelector(getSubscription(org.id))
  const dispatch = useDispatch()
  const elements = useElements()

  useEffect(() => {
    dispatch(fetchSubscription(org.id))
  }, [org.id])

  const handleSubmit = useCallback(
    async values => {
      const cardElement = elements.getElement(CardElement)

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: values,
      })

      if (error) {
        return processError(error)
      }

      // Submit the billing info
      // If successful, clear error banner and close
      try {
        await dispatch(
          updateSubscription(org.id, { paymentMethod: paymentMethod.id })
        )

        await dispatch(fetchCurrentUser())

        goBack()
      } catch (err) {
        processError(err)
      }
    },
    [org.id]
  )

  if (!org.activePlan) {
    return null
  }

  return (
    <CenteredModal>
      <h1>Add New Payment Card</h1>
      {subscription ? (
        <Form
          subscription={subscription}
          onSubmit={handleSubmit}
          onCancel={goBack}
        />
      ) : (
        <EmptyState loading />
      )}
    </CenteredModal>
  )
}

export default function UpdateCardModalWrapper() {
  const stripe = getStripe()

  return (
    <Elements stripe={stripe}>
      <Modal />
    </Elements>
  )
}
