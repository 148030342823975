const { getCurrency } = require('./currencies')

const LABELS = {
  materials: 'Materials',
  supplies: 'Materials',
  products: 'Products',
  supply: 'Materials',
  product: 'Products',
}

const SINGULAR_LABELS = {
  materials: 'Material',
  supplies: 'Material',
  products: 'Product',
  supply: 'Material',
  product: 'Product',
}

const PRODUCT = 'product'
const SUPPLY = 'supply'
const PRODUCTS = 'products'
const SUPPLIES = 'supplies'

const RESOURCE_TYPES = {
  supplies: SUPPLY,
  products: PRODUCT,
  materials: SUPPLY,
  supply: SUPPLY,
  product: PRODUCT,
  material: SUPPLY,
}

const PLURAL_TYPES = {
  supplies: SUPPLIES,
  products: PRODUCTS,
  materials: SUPPLIES,
  supply: SUPPLIES,
  product: PRODUCTS,
  material: SUPPLIES,
}

const SLUGS = {
  supplies: 'materials',
  products: 'products',
  materials: 'materials',
  supply: 'materials',
  product: 'products',
  material: 'materials',
}

const getLabel = (resourceType, count) => {
  if (count === 1) {
    return SINGULAR_LABELS[resourceType]
  } else {
    return LABELS[resourceType]
  }
}

const getPluralType = type => {
  return PLURAL_TYPES[type]
}

const normalizeType = type => {
  if (type === 'materials') {
    return 'supplies'
  }

  return type
}

// Units

const UNIT_TYPE_OPTIONS = [
  {
    label: 'Quantity',
    key: 'pieces',
    options: [{ label: 'Pieces', value: 'pieces' }],
  },
  {
    label: 'Weight',
    key: 'weight',
    options: [
      { label: 'Pounds', value: 'weight.lbs' },
      { label: 'Ounces', value: 'weight.oz' },
      { label: 'Kilograms', value: 'weight.kg' },
      { label: 'Grams', value: 'weight.grams' },
    ],
  },
  {
    label: 'Length',
    key: 'length',
    options: [
      { label: 'Feet', value: 'length.ft' },
      { label: 'Yards', value: 'length.yd' },
      { label: 'Inches', value: 'length.in' },
      { label: 'Meters', value: 'length.m' },
      { label: 'Centimeters', value: 'length.cm' },
    ],
  },
  {
    label: 'Area',
    key: 'area',
    options: [
      { label: 'Square Feet', value: 'area.sqft' },
      { label: 'Square Inches', value: 'area.sqin' },
      { label: 'Square Meters', value: 'area.sqm' },
      { label: 'Square Centimeters', value: 'area.sqcm' },
    ],
  },
  {
    label: 'Volume',
    key: 'volume',
    options: [
      { label: 'Fluid Ounces', value: 'volume.oz' },
      { label: 'Gallons', value: 'volume.ga' },
      { label: 'Quarts', value: 'volume.qt' },
      { label: 'Liters', value: 'volume.liters' },
      { label: 'Milliliters', value: 'volume.ml' },
    ],
  },
]

const UNIT_LABELS = {
  'weight.lbs': ['lbs', 'lb'],
  'weight.oz': 'oz',
  'weight.kg': 'kg',
  'weight.grams': 'g',

  'length.ft': 'ft',
  'length.yd': 'yd',
  'length.in': 'in',
  'length.m': 'm',
  'length.cm': 'cm',

  'area.sqft': 'sq. ft',
  'area.sqin': 'sq. in',
  'area.sqm': 'sq. m',
  'area.sqcm': 'sq. cm',

  'volume.oz': 'fl oz',
  'volume.ga': 'gal',
  'volume.qt': 'qt',
  'volume.liters': 'l',
  'volume.ml': 'ml',

  pieces: ['pieces', 'piece'],
}

const UNIT_LABELS_LONG = {
  'weight.lbs': ['Pounds', 'Pound'],
  'weight.oz': ['Ounces', 'Ounce'],
  'weight.kg': ['Kilograms', 'Kilogram'],
  'weight.grams': ['Grams', 'Gram'],

  'length.ft': ['Feet', 'Foot'],
  'length.yd': ['Yards', 'Yard'],
  'length.in': ['Inches', 'Inch'],
  'length.m': ['Meters', 'Meter'],
  'length.cm': ['Centimeters', 'Centimeter'],

  'area.sqft': ['Square Feet', 'Square Foot'],
  'area.sqin': ['Square Inches', 'Square Inch'],
  'area.sqm': ['Square Meters', 'Square Meter'],
  'area.sqcm': ['Square Centimeters', 'Square Centimeter'],

  'volume.oz': ['Fluid Ounces', 'Fluid Ounce'],
  'volume.ga': ['Gallons', 'Gallon'],
  'volume.qt': ['Quarts', 'Quart'],
  'volume.liters': ['Liters', 'Liter'],
  'volume.ml': ['Milliliters', 'Milliliter'],

  pieces: ['Pieces', 'Piece'],
}

const getCompatibleUnits = unit => {
  const unitPrefix = unit.split('.')[0]
  const unitGroup = UNIT_TYPE_OPTIONS.find(opt => opt.key === unitPrefix)

  return (unitGroup && unitGroup.options) || []
}

const unitsCompatible = (unit1, unit2) => {
  if (!unit1 || !unit2) {
    return true
  }

  const unit1Prefix = unit1.split('.')[0]
  const unit2Prefix = unit2.split('.')[0]

  return unit1Prefix === unit2Prefix
}

const getUnitLabel = (unit, number = 0, longLabels = false) => {
  if (!unit) unit = 'pieces'

  number = +number

  let label = longLabels ? UNIT_LABELS_LONG[unit] : UNIT_LABELS[unit]

  if (!label) label = unit

  if (Array.isArray(label)) label = number === 1 ? label[1] : label[0]

  return label
}

const isValidUnitType = unit => {
  return unit in UNIT_LABELS
}

const getArray = stringOrArray => {
  if (!stringOrArray) {
    return []
  }

  if (!Array.isArray(stringOrArray)) {
    return [stringOrArray]
  }

  return stringOrArray
}

const normalizeUnit = unit => {
  return unit.toLowerCase().replace(/[^a-z]/g, '')
}

const parseUnitType = unitName => {
  const normalizedName = normalizeUnit(unitName)

  for (let key of Object.keys(UNIT_LABELS)) {
    for (let itm of [
      ...getArray(UNIT_LABELS[key]),
      ...getArray(UNIT_LABELS_LONG[key]),
    ]) {
      if (normalizeUnit(itm) === normalizedName) {
        return key
      }
    }
  }

  return 'pieces'
}

const isNumeric = number => {
  if (typeof number === 'string') {
    return number.match(/^\-?\d*\.?\d+$/)
  }

  if (Number.isNaN(number)) {
    return false
  }

  return typeof number === 'number'
}

const formatNumber = (number, unit = null) => {
  if (!isNumeric(number)) {
    return '-'
  }

  const label = unit ? ` ${getUnitLabel(unit, number)}` : ''

  const formatter = Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
  })

  return `${formatter.format(number)}${label}`
}

const formatPrice = (number, unit, organization, round = true) => {
  if (!isNumeric(number)) {
    return '-'
  }

  const label = getUnitLabel(unit, 1)

  const price = formatCurrency(number, organization, round)

  return `${price}/${label}`
}

const formatCurrency = (number, organization, round = true) => {
  if (!isNumeric(number)) {
    return '-'
  }

  const currency = getCurrency(organization)

  const price = Intl.NumberFormat(currency.locale, {
    style: 'currency',
    currency: currency.code,
    maximumFractionDigits: round ? undefined : 6,
  }).format(number)

  return price
}

const isBelowMinimum = (stockLevel, resource) => {
  if (stockLevel <= 0) {
    return true
  }

  if (resource && resource.minimumQuantity) {
    return stockLevel <= resource.minimumQuantity
  }

  return false
}

module.exports = {
  PRODUCT,
  SUPPLY,
  LABELS,
  SINGULAR_LABELS,
  RESOURCE_TYPES,
  PLURAL_TYPES,
  SLUGS,
  getPluralType,
  normalizeType,
  UNIT_TYPE_OPTIONS,
  UNIT_LABELS,
  UNIT_LABELS_LONG,
  getLabel,
  getCompatibleUnits,
  unitsCompatible,
  getUnitLabel,
  formatNumber,
  formatPrice,
  formatCurrency,
  isBelowMinimum,
  isValidUnitType,
  parseUnitType,
}
