import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { downloadCSV } from '../../utils/api'
import { useSearchTerm, usePage } from '../../utils/hooks'
import { getCurrentOrganizationId } from '../../ducks/users'
import { fetchViews, getView } from '../../ducks/views'
import { fetchData } from '../../ducks/data'
import { IconButton } from '../Shared/Icon'
import { FullScreenLoader } from '../Shared/Loading'
import SearchBar from '../Shared/SearchBar'
import Emoji from '../Shared/Emoji'
import Overflow from '../Shared/Overflow'
import Query from './Query'
import Data from './Data'
import styles from './View.module.css'

const View = function View() {
  const wrapperRef = useRef(null)
  const searchInputRef = useRef(null)
  const [searchFocussed, setSearchFocussed] = useState(false)
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const { viewId } = useParams()
  const view = useSelector(getView(viewId))
  const page = usePage()
  const searchTerm = useSearchTerm()

  const handleFetch = useCallback(
    (page = 1) => {
      dispatch(
        fetchData(
          viewId,
          searchTerm,
          view?.visualization === 'chart' ? 'all' : page
        )
      )
    },
    [viewId, searchTerm, view?.visualization]
  )

  const toggleEditing = useCallback(() => {
    setEditing(!editing)

    if (wrapperRef.current) {
      wrapperRef.current.scrollTo(0, 0)
    }
  }, [setEditing, editing])

  const focusSearch = useCallback(() => {
    setSearchFocussed(true)

    if (searchInputRef.current) {
      searchInputRef.current.setAttribute('autofocus', true)
      searchInputRef.current.focus()
      searchInputRef.current.select()
    }
  }, [setSearchFocussed])

  const blurSearch = useCallback(() => {
    setSearchFocussed(false)

    if (searchInputRef.current) {
      searchInputRef.current.removeAttribute('autofocus')
    }
  }, [setSearchFocussed])

  useEffect(() => {
    if (view) {
      handleFetch(page)
    }
  }, [viewId, searchTerm, view?.visualization, page])

  const overflowOptions = useMemo(
    () => [
      {
        label: 'Download CSV',
        onClick: () => downloadCSV(viewId, searchTerm),
      },
    ],
    [viewId, searchTerm]
  )

  if (!view) {
    return <FullScreenLoader />
  }

  const { visualization } = view

  const searchActive = searchFocussed || searchTerm

  return (
    <div
      className={classNames(
        styles.wrapper,
        visualization === 'chart' && styles.chart,
        searchActive && styles.searchActive
      )}
      ref={wrapperRef}
    >
      <div className={styles.header}>
        <div className={styles.headerSub}>
          <h1 className={styles.title}>
            {view.emoji && <Emoji emoji={view.emoji} />}
            <span className={styles.titleSub}>{view.name || 'Untitled'}</span>
          </h1>
          <div className={styles.spacer} />
          <div className={styles.actions}>
            <IconButton
              onClick={toggleEditing}
              icon="edit"
              color="grey"
              expanded={editing}
            />
            <Overflow options={overflowOptions} />
          </div>
          <SearchBar
            onBlur={blurSearch}
            className={styles.search}
            enterKeyHint="search"
            inputRef={searchInputRef}
          />
          <div className={styles.searchIcon}>
            <IconButton icon="search" color="grey" onClick={focusSearch} />
          </div>
        </div>
      </div>
      {editing && (
        <div className={styles.query}>
          <Query />
        </div>
      )}
      <Data />
    </div>
  )
}

export default function Wrapper() {
  const dispatch = useDispatch()
  const { viewId } = useParams()
  const orgId = useSelector(getCurrentOrganizationId)

  useEffect(() => {
    dispatch(fetchViews(orgId))
  }, [])

  return <View key={viewId} />
}
