import React, { Component } from 'react'
import pick from 'lodash/pick'
import Textarea from 'react-textarea-autosize'
import classNames from 'classnames'

import { USE_COMMA } from '../../../../utils/decimals'
import { parseNumberInput } from '../../../../utils/numbers'
import InfoBubble from '../../InfoBubble'
import Arrows from './Arrows'

import styles from './WrappedInput.module.css'

export default class WrappedInput extends Component {
  state = {
    focussed: false,
  }

  getInputValue = e => {
    const { jsonOptions, type } = this.props
    const input = e.currentTarget
    let value = input.value

    if (input.tagName === 'SELECT') {
      let opt = input.selectedOptions[0]

      if (opt && opt.disabled) {
        return undefined
      } else if (value === '__empty__') {
        return null
      }
    }

    if (jsonOptions && typeof value === 'string' && value.match(/^[\[\{]/)) {
      value = JSON.parse(value)
    }

    if (value && type === 'number' && USE_COMMA) {
      value = value
        .replace(/\./g, '__comma__')
        .replace(/,/, '.')
        .replace(/__comma__/g, ',')
    }

    return value
  }

  parseValue(value) {
    return parseNumberInput(value, {
      ...pick(this.props, ['min', 'max', 'precision']),
      forcePeriod: true,
    })
  }

  handleChange = e => {
    let value = this.getInputValue(e)

    return this.handleChangeSub(value)
  }

  handleChangeSub = value => {
    let { input, onChangeValue, type } = this.props
    let { onChange } = input

    if (type === 'number') {
      const parsed = this.parseValue(value)

      if (parsed === null) return

      if (parsed === '') {
        value = null
      } else if (parsed !== value) {
        value = parsed
      }
    }

    onChange(value)

    if (onChangeValue) {
      onChangeValue(value)
    }
  }

  handleFocus = e => {
    let { input } = this.props
    let { onFocus } = input

    if (onFocus) {
      onFocus(e)
    }

    this.setState({ focussed: true })
  }

  handleBlur = e => {
    let { input } = this.props
    let { onBlur } = input

    let value = this.getInputValue(e)

    if (onBlur) {
      onBlur(value)
    }

    this.setState({ focussed: false })
  }

  stopPropagation = e => {
    e.stopPropagation()
  }

  forceFocus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  getOptions = () => {
    let { options, jsonOptions } = this.props

    if (options && jsonOptions) {
      return options.map(opt => {
        if (opt.value && typeof opt.value === 'object') {
          return {
            ...opt,
            value: JSON.stringify(opt.value),
          }
        }

        return opt
      })
    }

    return options
  }

  getValue = () => {
    let { input, jsonOptions, type } = this.props
    let { value } = input

    if (input.value && typeof input.value === 'object' && jsonOptions) {
      return JSON.stringify(value)
    }

    if (type === 'number' && USE_COMMA) {
      value = value
        .toString()
        .replace(/,/g, '__comma__')
        .replace(/\./, ',')
        .replace(/__comma__/g, '.')
    }

    return value
  }

  hasOptionForValue = value => {
    if (!value) return true

    const options = this.getOptions()
    let hasValue = false

    if (options) {
      for (let opt of options) {
        if (opt && (opt.value === value || String(opt.value) === value)) {
          hasValue = true
        } else if (opt && opt.options) {
          for (let childOpt of opt.options) {
            if (childOpt.value === value || String(childOpt.value) === value) {
              hasValue = true
            }
          }
        }
      }
    }

    return hasValue
  }

  setEmptyValue = () => {
    const { placeholder, autoSelect, input } = this.props
    const { onChange } = input
    const options = this.getOptions()
    const value = this.getValue()

    const placeholderIndex = placeholder ? 0 : -1

    const hasValue = value && this.hasOptionForValue(value)

    if (options && this.select && !hasValue) {
      if (autoSelect) {
        const index = placeholderIndex + 1
        this.select.selectedIndex = index
        const option = options[index]

        onChange(option?.value)
      } else {
        this.select.selectedIndex = placeholderIndex
      }
    }
  }

  componentDidMount() {
    this.setEmptyValue()
  }

  componentDidUpdate() {
    this.setEmptyValue()
  }

  inputRef = input => {
    const { inputRef } = this.props

    this.input = input

    if (inputRef) {
      inputRef.current = input
    }
  }

  selectRef = select => {
    const { inputRef } = this.props

    this.select = select

    if (inputRef) {
      inputRef.current = select
    }
  }

  renderInput(inputProps) {
    let { input, placeholder, disabled, minRows } = this.props
    let options = this.getOptions()
    let value = this.getValue()

    if (options) {
      return (
        <select
          name={input.name}
          onChange={this.handleChange}
          value={value}
          ref={this.selectRef}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {placeholder && <option disabled>{placeholder}</option>}
          {options.map((option, i) =>
            !option ? (
              // eslint-disable-next-line react/no-array-index-key
              <option key={i} disabled value="__divider">
                {' '}
              </option>
            ) : option.options ? (
              <React.Fragment key={option.value || i}>
                {(placeholder || i > 0) && <option disabled> </option>}
                <optgroup label={option.label} key={option.label}>
                  {option.options.map(child => (
                    <option
                      value={child.value}
                      key={child.value}
                      disabled={child.disabled}
                    >
                      {child.label}
                    </option>
                  ))}
                </optgroup>
              </React.Fragment>
            ) : (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            )
          )}
        </select>
      )
    }

    const Component = inputProps.type === 'textarea' ? Textarea : 'input'

    const additionalProps = {}

    if (inputProps.type === 'textarea') {
      additionalProps.minRows = minRows || 2
    }

    const isNumber = inputProps.type === 'number'
    const type = isNumber ? 'text' : inputProps.type
    const inputMode = isNumber ? 'decimal' : additionalProps.inputMode

    return (
      <Component
        {...inputProps}
        {...input}
        {...additionalProps}
        type={type}
        className={styles.input}
        onChange={this.handleChange}
        onMouseDown={this.stopPropagation}
        onMouseUp={this.stopPropagation}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={this.inputRef}
        disabled={disabled}
        step={isNumber ? 'any' : undefined}
        inputMode={inputMode}
        value={value}
      />
    )
  }

  render() {
    const { focussed } = this.state

    const {
      meta,
      label,
      input,
      className,
      prefix,
      suffix,
      inverted,
      noPad,
      attractBelow,
      xsmall,
      small,
      medium,
      helpText,
      disabled,
      softDisabled,
      underline,
      borderless,
      transparent,
      errorClassName,
      showImmediateFeedback,
      required,
      info,
      rightAlignError,
      min,
      max,

      // Filter out non-input props
      onChangeValue,

      ...props
    } = this.props

    const { type, options } = this.props
    const { touched, error, submitting } = meta || {}
    const { value } = input
    const typeClassName = styles[`type-${type}`]
    const showError = showImmediateFeedback ? value && error : touched && error

    const hasInvalidValue = options && !this.hasOptionForValue(value)

    const isNumber = type === 'number'

    return (
      <div
        onMouseDown={this.forceFocus}
        onMouseUp={this.forceFocus}
        className={classNames(styles.wrapper, className, typeClassName, {
          [styles.error]: showError || hasInvalidValue,
          [styles.focussed]: focussed,
          [styles.inverted]: inverted,
          [styles.noPad]: noPad,
          [styles.attractBelow]: attractBelow,
          [styles.empty]: !input.value,
          [styles.disabled]: disabled || submitting,
          [styles.softDisabled]: softDisabled,
          [styles.underline]: underline,
          [styles.xsmall]: xsmall,
          [styles.small]: small,
          [styles.medium]: medium,
          [styles.borderless]: borderless,
          [styles.transparent]: transparent,
        })}
      >
        {(label || helpText) && (
          <div className={styles.labelWrapper}>
            {label ? (
              <label>
                {label}
                {info && (
                  <span className={styles.info}>
                    <InfoBubble>{info}</InfoBubble>
                  </span>
                )}
                {required && <span className="danger"> •</span>}
              </label>
            ) : null}
            {helpText ? <p className={styles.helpText}>{helpText}</p> : null}
          </div>
        )}
        <div className={styles.innerWrapper}>
          {prefix ? <div className={styles.prefix}>{prefix}</div> : null}
          <div className={styles.inputWrapper}>{this.renderInput(props)}</div>
          {suffix ? <div className={styles.suffix}>{suffix}</div> : null}
          {isNumber && !disabled && !submitting && (
            <Arrows
              value={input.value}
              onChange={this.handleChangeSub}
              min={min}
              max={max}
            />
          )}
        </div>
        {showError ? (
          <div
            className={classNames(
              styles.errorWrapper,
              rightAlignError && styles.rightAlignError
            )}
          >
            <div className={classNames(styles.errorMessage, errorClassName)}>
              {error}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
