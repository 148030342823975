import axios from 'axios'
import { download } from './downloads'

const apiURL = process.env.REACT_APP_COMBINED_APP
  ? ''
  : process.env.REACT_APP_BACKEND_URL

const baseURL = `${apiURL}/api`

const headers = {}
let authToken

const backend = axios.create({
  baseURL,
  headers,
})

export const setAuthToken = token => {
  authToken = token

  if (token) {
    backend.defaults.headers.Authorization = `Bearer ${token}`
  } else {
    delete backend.defaults.headers.Authorization
  }
}

// Subscriptions

export const createCheckoutSession = (
  organizationId,
  planName,
  successURL,
  backURL
) => {
  return backend.post(`/organizations/${organizationId}/checkout-sessions`, {
    planName,
    successURL,
    backURL,
  })
}

export const createPortalSession = organizationId => {
  return backend.post(`/organizations/${organizationId}/portal-sessions`)
}

// Organizations

export const updateOrganization = (organizationId, organization) => {
  return backend.put(`/organizations/${organizationId}`, organization)
}

export const fetchOrganizationUsers = organizationId => {
  return backend.get(`/organizations/${organizationId}/users`)
}

export const removeUser = (organizationId, userId) => {
  return backend.delete(`/organizations/${organizationId}/users/${userId}`)
}

export const inviteUser = (organizationId, user) => {
  return backend.post(`/organizations/${organizationId}/invitations`, user)
}

export const cancelInvitation = (organizationId, invitationId) => {
  return backend.delete(
    `/organizations/${organizationId}/invitations/${invitationId}`
  )
}

export const fetchSubscription = organizationId => {
  return backend.get(`/organizations/${organizationId}/subscription`)
}

export const updateSubscription = (organizationId, data) => {
  return backend.put(`/organizations/${organizationId}/subscription`, data)
}

export const cancelSubscription = organizationId => {
  return backend.delete(`/organizations/${organizationId}/subscription`)
}

// Users

export const fetchCurrentUser = () => {
  return backend.get('/users/me')
}

export const saveUser = user => {
  return backend.put('/users/me', user)
}

export const login = data => {
  return backend.post('/sessions', data)
}

export const signup = data => {
  return backend.post('/users', data)
}

export const forgotPassword = data => {
  return backend.post('/users/forgot-password', data)
}

export const resetPassword = data => {
  return backend.post('/users/reset-password', data)
}

export const acceptInvitation = (invitationId, data) => {
  return backend.post(`/invitations/${invitationId}/accept`, data)
}

export const fetchInvitation = invitationId => {
  return backend.get(`/invitations/${invitationId}`)
}

export const validateEmail = email => {
  return backend.get(`/users/email-availability`, { params: { email } })
}

// Views

export const fetchViews = orgId => {
  return backend.get(`/organizations/${orgId}/views`)
}

export const createView = (orgId, data) => {
  return backend.post(`/organizations/${orgId}/views`, data)
}

export const updateView = (viewId, data) => {
  return backend.put(`/views/${viewId}`, data)
}

export const createFolder = (orgId, data) => {
  return backend.post(`/organizations/${orgId}/folders`, data)
}

export const updateFolder = (folderId, data) => {
  return backend.put(`/folders/${folderId}`, data)
}

export const deleteFolder = folderId => {
  return backend.delete(`/folders/${folderId}`)
}

// Data

export const fetchViewData = (viewId, search, page = 1) => {
  return backend.get(`/views/${viewId}/data`, {
    params: {
      search,
      page,
    },
  })
}

export const downloadCSV = (viewId, search) => {
  download(
    `${baseURL}/views/${viewId}/data.csv?search=${search}&authToken=${authToken}`
  )
}

// Databases

export const fetchDatabases = orgId => {
  return backend.get(`/organizations/${orgId}/databases`)
}

export const fetchDatabase = databaseId => {
  return backend.get(`/databases/${databaseId}`)
}

export const createDatabase = (orgId, data) => {
  return backend.post(`/organizations/${orgId}/databases`, data)
}

export const updateDatabase = (databaseId, data) => {
  return backend.put(`/databases/${databaseId}`, data)
}

export const deleteDatabase = databaseId => {
  return backend.delete(`/databases/${databaseId}`)
}
