import React, { useCallback } from 'react'
import { SubmissionError } from 'redux-form'
import { Link, useHistory } from 'react-router-dom'

import { resetPassword } from '../../../utils/api'
import AuthPage from '../../Shared/AuthPage'
import Form from './Form'

export default function ResetPassword({ match }) {
  const { token } = match.params
  const history = useHistory()

  const handleSubmit = useCallback(
    async ({ password }) => {
      try {
        await resetPassword({ token, password })
        history.push('/login')
      } catch (err) {
        let error = 'An unknown error occurred'

        if (err.response && err.response.data && err.response.data.error) {
          error = err.response.data.error
        }

        throw new SubmissionError({
          _error: error,
        })
      }
    },
    [token]
  )

  return (
    <AuthPage
      title="Reset Password"
      links={
        <p>
          <Link to="/login">Back to Login</Link>
        </p>
      }
    >
      <Form onSubmit={handleSubmit} />
    </AuthPage>
  )
}
