import React from 'react'
import classNames from 'classnames'

import Loading from '../Loading'

import styles from './EmptyState.module.css'

export default function EmptyState({
  loading,
  children,
  className,
  tight,
  full,
  black,
}) {
  return (
    <div
      className={classNames(
        styles.emptyState,
        className,
        tight && styles.tight,
        full && styles.full,
        black && styles.black
      )}
    >
      {loading ? <Loading /> : children}
    </div>
  )
}
