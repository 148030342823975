import * as API from '../utils/api'

const FETCH = 'FETCH_SUBSCRIPTION'
const UPDATE = 'UPDATE_SUBSCRIPTION'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${FETCH}_FULFILLED`) {
    let { organizationId } = action.meta
    let { data } = action.payload

    return {
      ...state,
      [organizationId]: data,
    }
  }

  return state
}

// Actions

export const fetchSubscription = organizationId => ({
  type: FETCH,
  meta: { organizationId },
  payload: API.fetchSubscription(organizationId),
})

export const updateSubscription = (organizationId, data) => ({
  type: UPDATE,
  meta: { organizationId },
  payload: API.updateSubscription(organizationId, data),
})

// Selectors

export const getSubscription = organizationId => state => {
  return state.subscriptions[organizationId]
}
