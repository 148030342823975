import { getDecimalCharacter } from './decimals'

export const round = (number, digits = 0, minDigits) => {
  if (minDigits === undefined || digits < minDigits) {
    minDigits = digits
  }

  let result = (+(+number).toFixed(7)).toFixed(digits)

  for (let i = minDigits; i < digits; i += 1) {
    const digit = (result.split('.')[1] || '').slice(-1)

    if (digit === '0') {
      result = result.slice(0, -1)

      if (result.slice(-1) === '.') {
        result = result.slice(0, -1)
      }
    } else {
      break
    }
  }

  return result
}

export const parseNumberInput = (value, opts = {}) => {
  const {
    min = -99999999,
    max = 99999999,
    precision = 6,
    forceComma = false,
    forcePeriod = false,
  } = opts

  const decimal = forceComma ? ',' : forcePeriod ? '.' : getDecimalCharacter()

  if (decimal === ',' && value) {
    value = String(value).replace(/\./g, '').replace(/,/, '.')
  } else if (value) {
    value = String(value).replace(/,/g, '')
  }

  const parsed = +value

  if (value === '') return ''

  const pattern = +min >= 0 ? /^\d*\.?\d*$/ : /^\-?\d*\.?\d*$/

  if (!value.match(pattern)) return null

  if (parsed < +min || parsed > +max) return null

  // Eliminate leading zeros
  const zeroMatch = value.match(/^(\-?)0+(\d.*?)$/)

  if (zeroMatch) {
    value = `${zeroMatch[1]}${zeroMatch[2]}`
  }

  // Limit decimal places
  if (precision) {
    const match = value.match(/^(.*\.)(\d+)$/)

    if (match) {
      const decimals = match[2]

      if (decimals.length > +precision) {
        value = match[1] + decimals.slice(0, +precision)
      }
    }
  }

  if (decimal === ',') {
    return value.replace(/\./, ',')
  }

  return value
}
