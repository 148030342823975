const matchSearchTerm = (text, searchTerm) => {
  const textPieces = text.toLowerCase().split(/\s+/)
  const searchTerms = searchTerm.toLowerCase().split(/\s+/)

  for (let term of searchTerms) {
    if (textPieces.filter(p => p.match(term)).length === 0) {
      return false
    }
  }

  return true
}

module.exports = {
  matchSearchTerm,
}
