const deepEqual = require('deep-equal')

const identity = itm => itm

const sameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  arr1 = arr1.slice(0)
  arr2 = arr2.slice(0)

  arr1.sort((a, b) => a - b)
  arr2.sort((a, b) => a - b)

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

const uniqueElements = (arr, getter = identity, deep = false) => {
  let set = new Set()
  let result = []

  arr.forEach(element => {
    let val = getter(element)

    if (deep) {
      let exists = false

      for (let i = 0; i < result.length; i += 1) {
        if (deepEqual(getter(result[i]), val)) {
          exists = true

          break
        }
      }

      if (!exists) {
        result.push(element)
      }
    } else {
      if (!set.has(val)) {
        result.push(element)
        set.add(val)
      }
    }
  })

  return result
}

const xor = (arr1, arr2) => {
  let newArr = arr1.slice(0)

  arr2.forEach(itm => {
    let index = newArr.indexOf(itm)

    if (index === -1) {
      newArr.push(itm)
    } else {
      newArr.splice(index, 1)
    }
  })

  return newArr
}

const sort = (arr, keyFunc = itm => itm, reverse = false) => {
  return arr.slice(0).sort((a, b) => {
    let aKey = keyFunc(a)
    let bKey = keyFunc(b)

    const BLANK = ['', null, undefined]
    const FACTOR = reverse ? -1 : 1

    if (BLANK.includes(aKey) && BLANK.includes(bKey)) {
      return 0
    } else if (BLANK.includes(aKey)) {
      return 1
    } else if (BLANK.includes(bKey)) {
      return -1
    }

    if (aKey < bKey) {
      return -1 * FACTOR
    } else if (bKey < aKey) {
      return 1 * FACTOR
    } else {
      return 0
    }
  })
}

// Single-level array-flattening function
const flatten = arr => {
  let result = []

  for (let itm of arr) {
    result = result.concat(itm)
  }

  return result
}

// Array.indexOf but with an accessor func
// Useful because of immutability
const indexOf = (arr, accessor) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (accessor(arr[i])) {
      return i
    }
  }

  return -1
}

// Similar to array.join, but keeps as array and inserts new elements
// around existing elements
const padArray = (array, delimeter) => {
  let result = [delimeter]

  for (let itm of array) {
    result.push(itm)
    result.push(delimeter)
  }

  return result
}

module.exports = {
  sameElements,
  uniqueElements,
  xor,
  sort,
  flatten,
  indexOf,
  padArray,
}
