import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchInput } from '../SearchBar'
import { getSearchTerm, searchViews } from '../../../ducks/views'

export default function ViewSearch({ ...props }) {
  const searchTerm = useSelector(getSearchTerm)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    value => {
      dispatch(searchViews(value))
    },
    [dispatch]
  )

  return (
    <SearchInput
      {...props}
      value={searchTerm}
      onChange={handleChange}
      autoComplete="off"
    />
  )
}
