import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'

import debounce from 'lodash/debounce'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import EventEmitter from 'eventemitter3'

import { goBack } from '../../../utils/history'

import { IconButton } from '../Icon'

import styles from './Modal.module.css'

const ModalContext = createContext(() => {})

// Create event emitter
const eventEmitter = new EventEmitter()
const CLOSE_EVENT = Symbol('CLOSE_MODAL')

const closeModal = e => {
  if (e.pleaseIgnore) {
    return
  }

  for (let el = e.target; el; el = el.parentElement) {
    // Assume that if we click a link we don't need to close
    if (el.nodeName === 'A' && el.href) {
      return
    }
  }

  eventEmitter.emit(CLOSE_EVENT)
}

// Store container element
let portalContainer = null
let footerContainer = null

const Modal = function Modal({ children, onClose = goBack, showCloseButton }) {
  const setVisibility = useContext(ModalContext)

  useEffect(() => {
    setVisibility(true)

    return () => {
      setVisibility(false)
    }
  }, [setVisibility])

  useEffect(() => {
    if (!onClose) {
      throw new Error('Modal onClose is null or undefined')
    }

    eventEmitter.addListener(CLOSE_EVENT, onClose)

    return () => {
      eventEmitter.removeListener(CLOSE_EVENT, onClose)
    }
  }, [onClose])

  return ReactDOM.createPortal(
    <>
      <div className={styles.modal}>{children}</div>
      {onClose && showCloseButton && (
        <a className={styles.closeButton} onClick={onClose}>
          X
        </a>
      )}
    </>,
    portalContainer
  )
}

Modal.Footer = function ModalFooter({
  children,
  className,
  additionalContent,
}) {
  return ReactDOM.createPortal(
    <div className={styles.footerWrapper}>
      <div className={classNames(styles.footer, className)}>{children}</div>
      {additionalContent && (
        <div className={styles.footerAdditional}>{additionalContent}</div>
      )}
    </div>,
    footerContainer
  )
}

const CloseTrigger = function ModalCloseTrigger({ children }) {
  const ref = useRef(null)
  const div = ref.current

  useEffect(() => {
    if (div) {
      div.addEventListener('click', closeModal)
    }

    return () => {
      if (div) {
        div.removeEventListener('click', closeModal)
      }
    }
  }, [div])

  return <div ref={ref}>{children}</div>
}

Modal.Provider = function ModalProvider({ children }) {
  const [visible, setVisible] = useState(false)

  const handleSetVisible = useCallback(
    debounce(visible => {
      setVisible(visible)
    }, 10),
    [setVisible]
  )

  return (
    <ModalContext.Provider value={handleSetVisible}>
      <CloseTrigger>{children}</CloseTrigger>
      <div className={classNames(styles.portal, visible && styles.visible)}>
        <div className={styles.content}>
          <div
            className={styles.contentSub}
            ref={el => (portalContainer = el)}
          />
        </div>
        <div
          className={styles.footerContainer}
          ref={el => (footerContainer = el)}
        />
      </div>
    </ModalContext.Provider>
  )
}

Modal.Header = function ModalHeader({ children, hideBackButton = false }) {
  return (
    <div className={styles.header}>
      {!hideBackButton && (
        <IconButton large color="lightGrey" icon="back" onClick={closeModal} />
      )}
      <div className={styles.headerContent}>{children}</div>
    </div>
  )
}

export default Modal
