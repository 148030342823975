import * as API from '../utils/api'
import { SWITCH_ORGANIZATION } from './users'

const FETCH = 'FETCH_ORGANIZATION_USERS'
const INVITE = 'INVITE_USER'
const CANCEL_INVITATION = 'CANCEL_INVITATION'
const REMOVE_USER = 'REMOVE_ORGANIZATION_USER'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${FETCH}_FULFILLED`) {
    let { organizationId } = action.meta
    let users = action.payload.data

    return {
      ...state,
      [organizationId]: users,
    }
  }

  if (
    action.type === `${REMOVE_USER}_FULFILLED` ||
    action.type === `${CANCEL_INVITATION}_FULFILLED`
  ) {
    let { organizationId, userId } = action.meta

    let users = state[organizationId].filter(u => u.id !== userId)

    return {
      ...state,
      [organizationId]: users,
    }
  }

  if (action.type === `${INVITE}_FULFILLED`) {
    let { organizationId, email } = action.meta
    let { id, existingUser } = action.payload.data

    let prevUsers = state[organizationId] || []

    let result = [...prevUsers, existingUser || { id, email, invitation: true }]

    return {
      ...state,
      [organizationId]: result,
    }
  }

  if (action.type === SWITCH_ORGANIZATION) {
    return INITIAL_STATE
  }

  return state
}

// Actions

export const fetchOrganizationUsers = organizationId => ({
  type: FETCH,
  meta: { organizationId },
  payload: API.fetchOrganizationUsers(organizationId),
})

export const removeUser = (organizationId, userId) => ({
  type: REMOVE_USER,
  meta: { organizationId, userId },
  payload: API.removeUser(organizationId, userId),
})

export const inviteUser = (organizationId, email, role) => ({
  type: INVITE,
  meta: { organizationId, email },
  payload: API.inviteUser(organizationId, { email, role }),
})

export const cancelInvitation = (organizationId, invitationId) => ({
  type: CANCEL_INVITATION,
  meta: { organizationId, userId: invitationId },
  payload: API.cancelInvitation(organizationId, invitationId),
})

// Selectors

export const getOrganizationUsers = organizationId => state => {
  return state.organizationUsers[organizationId]
}
