import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import Highlighter from 'react-highlight-words'

import { getDropTarget } from '../../../utils/dragging'
import { alertError } from '../../../utils/errors'

import {
  listenForCommand,
  getCommandLabel,
  useCommandPressed,
} from '../../../utils/commands'

import { updateAny, toggleFolder, deleteFolder } from '../../../ducks/views'
import Emoji from '../Emoji'
import Icon from '../Icon'
import Overflow from '../Overflow'
import EventSuppressor from '../EventSuppressor'

import styles from './Navbar.module.css'

const ViewLink = function ViewLink({
  view,
  focussed,
  depth,
  searchTerm,
  command,
}) {
  const history = useHistory()
  const icon = view.visualization || 'table'
  const { emoji } = view

  const goToView = useCallback(() => {
    history.push(`/views/${view.id}`)
  }, [view.id])

  useEffect(() => {
    if (command) {
      return listenForCommand(command, goToView)
    }
  }, [command, goToView])

  return (
    <NavLink
      to={`/views/${view.id}`}
      className={classNames(
        styles.link,
        focussed && styles.focussed,
        styles[`depth-${depth}`]
      )}
      activeClassName={styles.active}
    >
      <span className={styles.icon}>
        {emoji ? (
          <Emoji emoji={emoji} size="sm" className={styles.emoji} />
        ) : (
          <Icon icon={icon} color="lightGrey" />
        )}
      </span>
      <div className={styles.label}>
        <Highlighter
          autoEscape
          searchWords={searchTerm ? searchTerm.trim().split(/\s+/g) : []}
          textToHighlight={view.name || 'Untitled'}
        />
      </div>
      {command && (
        <span className={styles.command}>{getCommandLabel(command)}</span>
      )}
    </NavLink>
  )
}

const FolderItem = function FolderItem({
  folder,
  expanded,
  depth,
  searchTerm,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const expandIcon = expanded ? 'collapse' : 'expand'
  const { emoji } = folder

  const handleExpand = useCallback(() => {
    dispatch(toggleFolder(folder.id))
  }, [folder.id, dispatch])

  const handleDelete = useCallback(async () => {
    if (!window.confirm('Are you sure you want to delete this folder?')) {
      return
    }

    try {
      await dispatch(deleteFolder(folder.id))
    } catch (err) {
      alertError(err)
    }
  }, [folder.id, dispatch])

  const handleEdit = useCallback(() => {
    const { pathname, search } = window.location
    const editURL = `${pathname}/folders/${folder.id}/edit${search}`

    history.push(editURL)
  }, [history])

  const options = useMemo(
    () => [
      {
        label: 'Edit Details',
        onClick: handleEdit,
      },
      {
        label: 'Delete',
        className: 'danger',
        onClick: handleDelete,
      },
    ],
    [handleDelete]
  )

  return (
    <EventSuppressor>
      <span
        className={classNames(styles.folder, styles[`depth-${depth}`])}
        onClick={handleExpand}
      >
        <span className={classNames(styles.icon, styles.expand)}>
          <Icon icon={expandIcon} color="lightGrey" />
        </span>
        <span className={styles.icon}>
          {emoji ? (
            <Emoji emoji={emoji} size="sm" className={styles.emoji} />
          ) : (
            <Icon icon="folder" color="lightGrey" />
          )}
        </span>
        <div className={styles.label}>
          <Highlighter
            autoEscape
            searchWords={[searchTerm]}
            textToHighlight={folder.name || 'Untitled'}
          />
        </div>
        <Overflow
          options={options}
          className={styles.overflow}
          activeClassName={styles.overflowActive}
        />
      </span>
    </EventSuppressor>
  )
}

const Item = function Item({
  item,
  startDrag,
  searchTerm,
  focussedId,
  numericCommands,
}) {
  const isFolder = item.data.__isFolder__

  const handleMouseDown = useCallback(
    e => {
      if (window.innerWidth < 940) return

      e.preventDefault()
      e.stopPropagation()
      startDrag(item, e)
    },
    [startDrag, item]
  )

  const focussed = focussedId === item.id

  return (
    <div className={styles.item} onMouseDown={handleMouseDown}>
      {isFolder ? (
        <FolderItem
          folder={item.data}
          expanded={item.expanded}
          depth={item.depth}
          searchTerm={searchTerm}
        />
      ) : (
        <ViewLink
          view={item.data}
          depth={item.depth}
          searchTerm={searchTerm}
          focussed={focussed}
          command={numericCommands?.[item.id]}
        />
      )}
      {item.expanded && item.children?.length > 0 && (
        <Children
          items={item.children}
          startDrag={startDrag}
          searchTerm={searchTerm}
          focussedId={focussedId}
          numericCommands={numericCommands}
        />
      )}
    </div>
  )
}

const Children = function Children({
  items,
  startDrag,
  searchTerm,
  focussedId,
  numericCommands,
}) {
  return (
    <div className={styles.children}>
      {items.map(item => (
        <Item
          key={item.id}
          item={item}
          startDrag={startDrag}
          searchTerm={searchTerm}
          focussedId={focussedId}
          numericCommands={numericCommands}
        />
      ))}
    </div>
  )
}

const ActiveDrag = function ActiveDrag({ activeDrag, items }) {
  const { currentPoint, startPoint, offsetTop, layerOffset } = activeDrag

  const dragStyles = activeDrag && {
    top: currentPoint[1] - startPoint[1] + offsetTop + layerOffset,
    left: currentPoint[0] - startPoint[0],
  }

  const { dropPosition, dropInside } = getDropTarget(items, activeDrag)

  const dropIndicatorStyles = {
    top: dropPosition * 30 + offsetTop - 2,
    height: dropInside ? 30 : 2,
  }

  return (
    <>
      <div className={styles.activeDrag} style={dragStyles}>
        <Item item={activeDrag.item} />
      </div>
      <div className={styles.dropIndicator} style={dropIndicatorStyles} />
    </>
  )
}

export default function Items({
  views,
  searchTerm,
  focussedId,
  numericCommands,
}) {
  const dispatch = useDispatch()
  const [activeDrag, setActiveDrag] = useState(null)
  const wrapperRef = useRef()
  const commandPressed = useCommandPressed()

  const dragging = activeDrag?.distance >= 5

  const startDrag = useCallback(
    (item, e) => {
      if (!wrapperRef.current) return

      const offsetTop = wrapperRef.current.getBoundingClientRect().top

      const layerOffset =
        e.currentTarget.getBoundingClientRect().top - offsetTop

      const startPoint = [e.clientX, e.clientY]

      setActiveDrag({
        item,
        offsetTop,
        layerOffset,
        startPoint,
        currentPoint: startPoint,
      })
    },
    [setActiveDrag]
  )

  const drag = useCallback(
    e => {
      if (!activeDrag) return

      const { startPoint } = activeDrag
      const currentPoint = [e.clientX, e.clientY]

      const distance = Math.sqrt(
        (currentPoint[0] - startPoint[0]) ** 2 +
          (currentPoint[1] - startPoint[1]) ** 2
      )

      setActiveDrag({
        ...activeDrag,
        currentPoint,
        distance,
      })
    },
    [activeDrag]
  )

  const endDrag = useCallback(
    e => {
      if (!activeDrag) return

      const { item, distance } = activeDrag
      setActiveDrag(null)

      if (!(distance >= 5)) return

      const result = getDropTarget(views, activeDrag)
      const { order, folderId } = result

      dispatch(
        updateAny({
          ...item.data,
          order,
          FolderId: folderId,
        })
      )
    },
    [activeDrag, views]
  )

  useEffect(() => {
    if (!activeDrag) return

    document.addEventListener('mousemove', drag)
    document.addEventListener('mouseup', endDrag)

    return () => {
      document.removeEventListener('mousemove', drag)
      document.removeEventListener('mouseup', endDrag)
    }
  }, [activeDrag])

  if (!views) return null

  return (
    <div
      className={classNames(
        styles.items,
        dragging && styles.dragging,
        commandPressed && styles.commandPressed
      )}
      ref={wrapperRef}
    >
      <Children
        items={views}
        startDrag={startDrag}
        searchTerm={searchTerm}
        focussedId={focussedId}
        numericCommands={numericCommands}
      />
      {dragging && <ActiveDrag activeDrag={activeDrag} items={views} />}
    </div>
  )
}
