import React from 'react'
import { reduxForm, Field } from 'redux-form'

import { useSelector } from 'react-redux'
import { getDatabases } from '../../../ducks/databases'
import CodeEditor from '../../Shared/Forms/CodeEditor'
import WrappedInput from '../../Shared/Forms/WrappedInput'
import EmojiPicker from '../../Shared/Forms/EmojiPicker'
import styles from './Query.module.css'

const FORM_NAME = 'queryForm'

const Form = ({ handleSubmit, submitting, view }) => {
  const databases = useSelector(getDatabases)

  const databaseOptions = databases.map(database => ({
    label: database.name,
    value: database.id,
  }))

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <Field
          name="emoji"
          component={EmojiPicker}
          label="Emoji"
          defaultIcon={view.visualization || 'table'}
        />
        <Field
          noPad
          name="name"
          component={WrappedInput}
          label="View Name"
          placeholder="Untitled"
          className={styles.input}
          autoComplete="off"
        />
        <Field
          noPad
          autoSelect
          name="DatabaseId"
          component={WrappedInput}
          label="Database"
          options={databaseOptions}
          className={styles.input}
        />
      </div>
      <Field
        name="query"
        component={CodeEditor}
        placeholder="select * from users;"
      />
    </div>
  )
}

export default reduxForm({
  form: FORM_NAME,
})(Form)
