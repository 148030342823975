const VISIBILITY_OPTIONS = [
  { label: 'Public', value: 'public' },
  { label: 'Login Required', value: 'login' },
  { label: 'Invite Only', value: 'private' },
]

const VISIBILITY_LABELS = {
  public: 'Public',
  login: 'Login Required',
  private: 'Invite Only',
}

module.exports = {
  VISIBILITY_OPTIONS,
  VISIBILITY_LABELS,
}
