import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getView, updateView } from '../../../ducks/views'
import styles from './Tabs.module.css'

export default function Tabs() {
  const dispatch = useDispatch()
  const { viewId } = useParams()
  const view = useSelector(getView(viewId))
  const visualization = view.visualization || 'table'

  const handleChange = useCallback(() => {
    const newVisualization = visualization === 'table' ? 'chart' : 'table'

    dispatch(updateView(viewId, { visualization: newVisualization }))
  }, [visualization, viewId, dispatch])

  return (
    <div className={styles.tabs}>
      <button
        onClick={handleChange}
        className={classNames(visualization === 'table' && styles.active)}
      >
        Table
      </button>
      <button
        onClick={handleChange}
        className={classNames(visualization === 'chart' && styles.active)}
      >
        Chart
      </button>
    </div>
  )
}
