import React from 'react'
import classNames from 'classnames'

import EventSuppressor from '../EventSuppressor'
import styles from './CenteredModal.module.css'

const CenteredModal = function CenteredModal({
  children,
  onClose,
  lg,
  xl,
  contained,
  noPad,
}) {
  return (
    <div className={classNames(styles.wrapper, contained && styles.contained)}>
      <EventSuppressor className={styles.innerWrapper}>
        {onClose && <div className={styles.backdrop} onClick={onClose} />}
        <div
          className={classNames(
            styles.modal,
            lg && styles.lg,
            xl && styles.xl,
            noPad && styles.noPad
          )}
        >
          <div className={styles.modalContent}>{children}</div>
        </div>
      </EventSuppressor>
    </div>
  )
}

CenteredModal.Header = function Header({ children }) {
  return <h1 className={styles.header}>{children}</h1>
}

export default CenteredModal
