import React from 'react'
import { reduxForm, Field } from 'redux-form'
import RadioGroup from '../../Shared/Forms/RadioGroup'
import FormError from '../../Shared/Forms/FormError'
import Button from '../../Shared/Button'

const FORM_NAME = 'upgradeFeedback'

const OPTIONS = [
  { label: 'Squarespace', value: 'squarespace' },
  { label: 'Wix', value: 'wix' },
  { label: 'Wordpress / WooCommerce', value: 'wordpress' },
  { label: 'Big Commerce', value: 'bigcommerce' },
  { label: 'Square', value: 'square' },
]

const validate = values => {
  const errors = {}

  if (
    !values.shopHost ||
    (values.shopHost &&
      typeof values.shopHost === 'object' &&
      !values.shopHost.other)
  ) {
    errors.shopHost = 'Required'
  }

  return errors
}

const Form = function UpgradeFeedbackForm({
  handleSubmit,
  submitting,
  error,
  onCancel,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <h2>Who do you use to host your shop?</h2>
      <Field
        name="shopHost"
        component={RadioGroup}
        allowOther
        options={OPTIONS}
      />
      {error && <FormError>{error}</FormError>}
      <Button.Group align="right">
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary type="submit" loading={submitting}>
          Submit Feedback
        </Button>
      </Button.Group>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(Form)
