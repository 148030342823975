let decimalCharacter = null

export const getDecimalCharacter = () => {
  if (decimalCharacter) return decimalCharacter

  const num = 1.1
  decimalCharacter = num.toLocaleString().substring(1, 2)

  return decimalCharacter
}

export const USE_COMMA = getDecimalCharacter() === ','
