import React, { useCallback } from 'react'
import { SubmissionError } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import pick from 'lodash/pick'

import Form from './Form'

import {
  updateOrganization,
  getOrganization,
} from '../../../ducks/organizations'

export default function Info({ organizationId }) {
  const organization = useSelector(getOrganization(organizationId))
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(updateOrganization(organizationId, values))
      } catch (err) {
        throw new SubmissionError({
          _error: 'An unknown error occurred',
        })
      }
    },
    ['organizationId']
  )

  const initialValues = pick(organization, ['name', 'currency', 'website'])

  if (!initialValues.currency) {
    initialValues.currency = 'USD'
  }

  return <Form onSubmit={handleSubmit} initialValues={initialValues} />
}
