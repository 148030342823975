const SEARCH = Symbol('SEARCH')

const INITIAL_STATE = {}

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SEARCH: {
      return {
        ...state,
        [action.name]: action.value,
      }
    }
  }

  return state
}

// Actions

export const search = (name, value) => ({
  type: SEARCH,
  name,
  value,
})

// Selectors

export const getSearchTerm = name => state => {
  return state.search[name]
}
