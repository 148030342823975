export const download = url => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  document.body.appendChild(a)

  a.click()

  window.setTimeout(() => {
    document.body.removeChild(a)
  }, 100)
}
