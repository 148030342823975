import React, { createContext } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { getCurrentOrganization } from '../../../ducks/users'

import Navbar from '../Navbar'
import TrialBanner from './TrialBanner'

import styles from './Page.module.css'

export const TrialBannerContext = createContext(false)

export default function Page({ children, fullWidth }) {
  const org = useSelector(getCurrentOrganization)

  // TODO: Re-enable trial process
  const showTrialBanner = false //org && !org.activePlan

  return (
    <div
      className={classNames(
        'page-wrapper',
        styles.wrapper,
        fullWidth && styles.fullWidth,
        showTrialBanner && styles.bannerActive
      )}
    >
      <Navbar />
      {showTrialBanner && <TrialBanner organization={org} />}
      <TrialBannerContext.Provider value={showTrialBanner}>
        <div className={styles.content}>{children}</div>
      </TrialBannerContext.Provider>
    </div>
  )
}

export const spacerClassName = styles.childContent
