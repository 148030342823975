import React from 'react'
import classNames from 'classnames'
import { Emoji } from 'emoji-picker-react'
import { getUnifiedEmoji } from '../../../utils/emojis'
import styles from './Emoji.module.css'

export default function WrappedEmoji({ emoji, size = 'lg', className }) {
  const unified = getUnifiedEmoji(emoji)

  return (
    <div
      className={classNames(styles.wrapper, styles[`size-${size}`], className)}
    >
      <Emoji unified={unified} />
    </div>
  )
}
