const { getUnitPrice } = require('./units')
const { sort } = require('./arrays')

const UNCATEGORIZED = {
  id: 0,
  name: 'Uncategorized',
  color: 'transparent',
}

const generateInventoryReport = resources => {
  // Filter out deleted resources
  // Calculate unit price for each resource
  // Compute individual stock value for each resource
  const newResources = resources
    .filter(r => !r.deletedAt)
    .map(resource => ({
      id: resource.id,
      type: resource.type,
      name: resource.name,
      category: resource.Categories[0],
      stockLevel: resource.stockLevel || 0,
      unitPrice: getUnitPrice(resource).unitPrice,
      stockValue: Math.max(
        0,
        (getUnitPrice(resource).unitPrice || 0) * (resource.stockLevel || 0)
      ),
    }))

  const sorted = sort(newResources, resource => -resource.stockValue)

  // Group resources into categories
  const categories = {}

  for (let resource of sorted) {
    const category = resource.category || UNCATEGORIZED

    if (!categories[category.id]) {
      categories[category.id] = {
        category,
        resources: [],
      }
    }

    categories[category.id].resources.push(resource)
  }

  // Calculate category totals
  for (let id of Object.keys(categories)) {
    categories[id].stockValue = categories[id].resources
      .map(itm => itm.stockValue)
      .reduce((a, b) => a + b, 0)
  }

  // Sort categories
  const sortedCategories = sort(
    Object.keys(categories).map(id => categories[id]),
    itm => -itm.stockValue
  )

  // Calculate grand total
  const result = {
    stockValue: sortedCategories
      .map(c => c.stockValue)
      .reduce((a, b) => a + b, 0),
    categories: sortedCategories,
  }

  return result
}

const generateCSVInventoryReport = resources => {
  const reportData = generateInventoryReport(resources)

  const data = [
    ['Category', 'Resource', 'Stock Level', 'Unit Price', 'Inventory Value'],
  ]

  for (let category of reportData.categories) {
    for (let resource of category.resources) {
      data.push([
        category.category.name,
        resource.name,
        resource.stockLevel,
        resource.unitPrice ? resource.unitPrice.toFixed(2) : resource.unitPrice,
        (resource.stockValue || 0).toFixed(2),
      ])
    }
  }

  data.push([])
  data.push(['Totals'])

  for (let category of reportData.categories) {
    data.push([
      category.category.name,
      '',
      '',
      '',
      category.stockValue.toFixed(2),
    ])
  }

  data.push(['Total', '', '', '', reportData.stockValue.toFixed(2)])

  return data
}

module.exports = {
  generateInventoryReport,
  generateCSVInventoryReport,
}
