import React, { useEffect } from 'react'
import QS from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  fetchCurrentUser,
  getCurrentOrganization,
  getOrganizations,
  changeOrganization,
  getCurrentUser,
} from '../../ducks/users'

import { FullScreenLoader } from '../Shared/Loading'
import Button from '../Shared/Button'
import UpgradeModal from '../Subscriptions/UpgradeModal'

export default function AppLoader({ children, onError }) {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchCurrentUser()).catch(err => {
      if (err.response && err.response.status === 401) {
        if (onError) {
          onError()
        } else {
          const url = `${window.location.pathname}${window.location.search}`

          let query = ''

          if (!['/'].includes(url)) {
            query = `?${QS.stringify({ redirect: url })}`
          }

          history.push(`/login${query}`)
        }
      }
    })
  }, [dispatch])

  const currentUser = useSelector(getCurrentUser)
  const currentOrg = useSelector(getCurrentOrganization)
  const orgs = useSelector(getOrganizations)

  useEffect(() => {
    const lastOrgId = currentUser && currentUser.lastOrganizationId
    const lastOrg = orgs.filter(o => o.id === lastOrgId)[0]
    const otherOrg = orgs[0]

    if (currentOrg) return

    if (!lastOrg && otherOrg) {
      dispatch(changeOrganization(otherOrg.id))
    }
  }, [dispatch, !!currentOrg, orgs])

  if (!currentOrg) {
    return <FullScreenLoader />
  }

  const showUpgradeModal = false

  return (
    <React.Fragment key={currentOrg.id}>
      {children}
      {showUpgradeModal && (
        <UpgradeModal cancelButton={<Button to="/settings">Settings</Button>} />
      )}
    </React.Fragment>
  )
}
