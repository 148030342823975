import React from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor'
import styles from './CodeEditor.module.css'

export default function WrappedCodeEditor(props) {
  const {
    input: { value, onChange },
    label,
    placeholder,
    language = 'sql',
  } = props

  return (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <CodeEditor
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        language={language}
        style={{
          fontSize: 16,
        }}
      />
    </div>
  )
}
