import React from 'react'
import classNames from 'classnames'
import EmptyState from '../EmptyState'

import './Loading.css'

export default function Loading({ expanded, small, inline }) {
  return (
    <div
      className={classNames('loading', {
        'loading-expanded': expanded,
        'loading-small': small,
        'loading-inline': inline,
      })}
    >
      <div className="loading-icon" />
    </div>
  )
}

export function FullScreenLoader() {
  return (
    <EmptyState className="loading-full-screen">
      <Loading large />
    </EmptyState>
  )
}
