import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { relativeDate } from '../../../utils/dates'
import { getDatabases } from '../../../ducks/databases'
import Card from '../../Shared/LegacyCard'
import Button from '../../Shared/Button'

import styles from './Databases.module.css'

const Item = function DatabaseItem({ database }) {
  const { name, type, createdAt } = database

  return (
    <div className={classNames(styles.item)}>
      <div className={styles.info}>
        <h3>
          <Link to={`/settings/databases/${database.id}/edit`}>{name}</Link>
        </h3>
        <p>{type}</p>
      </div>
      <div className={styles.meta}>
        <h5 className={styles.role}>Created {relativeDate(createdAt)}</h5>
      </div>
    </div>
  )
}

export default function Databases({ organizationId }) {
  const databases = useSelector(getDatabases)

  return (
    <Card>
      <h2>Databases</h2>
      <div className={styles.users}>
        {databases.map(db => (
          <Item key={db.id} database={db} />
        ))}
      </div>
      <Button.Group align="right">
        <Button to="/settings/databases/new" icon="add">
          Add Database
        </Button>
      </Button.Group>
    </Card>
  )
}
