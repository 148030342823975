import QS from 'qs'
import { useLocation } from 'react-router-dom'

export const useQueryParams = () => {
  const loc = useLocation()
  const params = QS.parse(loc.search, { ignoreQueryPrefix: true })

  return params
}

export const useSearchTerm = () => {
  const params = useQueryParams()

  return params.q || ''
}

export const usePage = () => {
  const params = useQueryParams()

  return params.page || 1
}

export const useParam = paramName => {
  return useQueryParams()[paramName]
}
