import React from 'react'
import classNames from 'classnames'

import styles from './Content.module.css'

export default function Content({ children, fullWidth = true, className }) {
  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        fullWidth && styles.fullWidth
      )}
    >
      {children}
    </div>
  )
}
