import Cookie from 'js-cookie'

const DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN
const COOKIE_NAME = 'inventory_session'

export const setCookie = () => {
  Cookie.set(COOKIE_NAME, 1, { domain: DOMAIN })
}

export const removeCookie = () => {
  Cookie.remove('inventory_session', { domain: DOMAIN })
}
