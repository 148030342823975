import React, { useState, useCallback } from 'react'
import { SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom'

import { forgotPassword } from '../../../utils/api'
import AuthPage from '../../Shared/AuthPage'
import Form from './Form'
import styles from './ForgotPassword.module.css'

export default function ForgotPassword() {
  const [complete, setComplete] = useState(false)

  const handleSubmit = useCallback(
    async values => {
      try {
        await forgotPassword(values)

        setComplete(true)
      } catch (err) {
        throw new SubmissionError('An unknown error occurred.')
      }
    },
    [setComplete]
  )

  return (
    <AuthPage
      links={
        <p>
          <Link to="/login">Back to Login</Link>
        </p>
      }
      title="Forgot Password"
    >
      {complete ? (
        <div className={styles.done}>
          <h2>Check your email for a reset link!</h2>
        </div>
      ) : (
        <Form onSubmit={handleSubmit} />
      )}
    </AuthPage>
  )
}
