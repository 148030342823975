import { SubmissionError } from 'redux-form'

const GENERIC_ERROR =
  'An unexpected error occurred. Please reload the page and try again.'

const NETWORK_ERROR =
  'We are having trouble connecting to our servers. Please check your internet connection and try again.'

export const processError = err => {
  const result = extractError(err)

  if (!result) {
    window.setTimeout(() => {
      throw err
    }, 0)
  }

  throw new SubmissionError(result)
}

export const alertError = err => {
  const result = getErrorString(err)
  console.error(err)

  window.alert(result)
}

export const getErrorString = err => {
  const result = extractError(err)

  if (result._error) {
    return result._error?.message || result._error
  } else {
    return Object.keys(result)
      .map(key => `${key}: ${result[key]}`)
      .join('\n\n')
  }
}

export const extractError = err => {
  const userError = err?.response?.data?.error
  const fieldErrors = err?.response?.data?.errors
  const errorPayload = err?.response?.data?.payload

  if (err.request && !err.response) {
    return {
      _error: NETWORK_ERROR,
    }
  }

  if (errorPayload?.type) {
    return { _error: errorPayload }
  }

  if (fieldErrors) {
    return fieldErrors
  }

  // Stripe error
  if (err.message && !(err instanceof Error)) {
    return { _error: err.message }
  }

  return {
    _error: userError || GENERIC_ERROR,
  }
}
