import React from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import { goBack } from '../../../utils/history'
import EmojiPicker from '../../Shared/Forms/EmojiPicker'
import WrappedInput from '../../Shared/Forms/WrappedInput'
import FormError from '../../Shared/Forms/FormError'
import Button from '../../Shared/Button'
import styles from './CreateModal.module.css'

const FORM_NAME = 'createFolder'

const Form = function FolderForm({ handleSubmit, submitting, error, form }) {
  const selector = formValueSelector(form)
  const name = useSelector(state => selector(state, 'name'))

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.row}>
        <Field name="emoji" component={EmojiPicker} defaultIcon="folder" />
        <Field
          noPad
          autoFocus
          name="name"
          component={WrappedInput}
          placeholder="Untitled"
          autoComplete="off"
          className={styles.name}
        />
      </div>
      {error && <FormError>{error}</FormError>}
      <Button.Group align="spread">
        <Button onClick={goBack}>Cancel</Button>
        <Button primary disabled={!name?.trim()} loading={submitting}>
          Save
        </Button>
      </Button.Group>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
})(Form)
