import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import QS from 'qs'

import { subscribe } from '../../../utils/stripe'
import { getCurrentOrganization } from '../../../ducks/users'
import Button from '../../Shared/Button'
import CenteredModal from '../../Shared/CenteredModal'
import FormError from '../../Shared/Forms/FormError'
import YearlySwitch from './YearlySwitch'

import styles from './UpgradeModal.module.css'

const getPlan = (provider, yearly) => {
  if (['wix', 'shopify'].includes(provider)) {
    return provider
  }

  return yearly ? 'businessYearly' : 'businessMonthly'
}

export const UpgradeForm = function UpgradeForm({
  hideCancel,
  redirect,
  hideYearly,
  provider,
  cancelButton,
}) {
  const [submitting, setSubmitting] = useState(false)
  const [yearly, setYearly] = useState(false)
  const [error, setError] = useState(null)
  const org = useSelector(getCurrentOrganization)
  const orgId = org && org.id
  const url = window.location.pathname

  const { redirect: queryRedirect } = QS.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const cancelURL = useMemo(() => {
    const urlMatch = url.match(/^(.*)\/upgrade\/?$/)

    return `${urlMatch ? `${urlMatch[1]}` : url}${window.location.search}`
  }, [url])

  const backURL = `${url}${window.location.search}`

  const successURL = useMemo(() => {
    if (queryRedirect) {
      return `${queryRedirect}?&upgrade=successful`
    } else if (redirect) {
      return `${redirect}?&upgrade=successful`
    }

    const urlMatch = url.match(/^(.*)\/upgrade\/?$/)

    return urlMatch
      ? `${urlMatch[1]}/upgrade-success?upgrade=successful`
      : `${url}?&upgrade=successful`
  }, [url, redirect, queryRedirect])

  const plan = getPlan(provider, yearly)

  const handlePay = useCallback(async () => {
    setSubmitting(true)

    try {
      setError(null)
      await subscribe(orgId, plan, successURL, backURL, yearly)
    } catch (err) {
      setError(err.message)
    }

    setSubmitting(false)
  }, [orgId, plan, yearly])

  if (org && org.activePlan) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className={styles.plans}>
        <div className={styles.planDetail}>
          <h2>Inventora Business</h2>
          <p className={styles.price}>
            <span className={styles.priceNumber}>{yearly ? '$15' : '$19'}</span>
            <span className={styles.perMonth}> per month</span>
          </p>
          <h3>What you’ll get:</h3>
          <ul className={styles.bulletPoints}>
            <li>Shopify & Etsy integrations</li>
            <li>Add up to 5 team members</li>
            <li>Access previously dated reports</li>
            <li>CSV downloads of data & reports</li>
            <li>Access to full history</li>
            <li>Unlimited Production Runs</li>
          </ul>
        </div>
      </div>
      {!hideYearly && (
        <div className={styles.yearlySwitch}>
          <YearlySwitch selected={yearly} onChange={setYearly} />
        </div>
      )}
      <div
        className={classNames(styles.actions, hideCancel && styles.hideCancel)}
      >
        {!hideCancel &&
          (cancelButton || (
            <Button replace to={cancelURL}>
              Cancel
            </Button>
          ))}
        <Button primary onClick={handlePay} loading={submitting}>
          Continue
        </Button>
      </div>
      {error && <FormError>{error}</FormError>}
    </>
  )
}

export default function UpgradeModal({ hideCancel, provider, cancelButton }) {
  const org = useSelector(getCurrentOrganization)
  const showTrial = org && !org.trialUsed && org.billingProvider !== 'wix'
  const hideYearly = provider === 'shopify'

  return (
    <CenteredModal>
      <h1>
        {showTrial
          ? 'Try Inventora Business free for 7 days!'
          : 'Upgrade to Inventora Business!'}
      </h1>
      <UpgradeForm
        hideCancel={hideCancel}
        provider={provider}
        hideYearly={hideYearly}
        cancelButton={cancelButton}
      />
    </CenteredModal>
  )
}
