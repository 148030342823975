const levenshtein = require('fast-levenshtein')

const { sort } = require('./arrays')

// Implements stable marriage problem to fuzzy-match two lists of labels
// Preferences are symmetrical, and therefore matches will be optimal

// LISTA & LISTB are arrays of strings

const matchLists = (listA, listB, maxRounds) => {
  if (!Array.isArray(listA)) {
    listA = []
  }

  if (!Array.isArray(listB)) {
    listB = []
  }

  const getScore = (a, b) => {
    return levenshtein.get(clean(a), clean(b))
  }

  let matches = matchSub(listA, listB, getScore)

  return matches
}

const matchSub = (listA, listB, getScore) => {
  const scores = []

  for (const a of listA) {
    for (const b of listB) {
      scores.push([a, b, getScore(a, b)])
    }
  }

  const sortedScores = sort(scores, itm => itm[2])

  const aTaken = new Set()
  const bTaken = new Set()

  const results = {}

  for (const [a, b] of sortedScores) {
    if (!aTaken.has(a) && !bTaken.has(b)) {
      results[a] = b

      aTaken.add(a)
      bTaken.add(b)
    }
  }

  return results
}

const getSuggestions = (a, listB) => {
  a = clean(a)
  return sort(listB, b => levenshtein.get(a, clean(b)))
}

const clean = str => {
  return str.toLowerCase()
}

module.exports = {
  matchLists,
  getSuggestions,
}
