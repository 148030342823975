import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentOrganization } from '../../../ducks/users'
import AppLoader from '../../App/AppLoader'
import { UpgradeForm } from '../UpgradeModal'

import styles from './Subscribe.module.css'

export default function Subscribe({
  hideYearly,
  onPay,
  provider,
  headline,
  redirect,
}) {
  const org = useSelector(getCurrentOrganization)

  const showTrial = org && !org.trialUsed && org.billingProvider !== 'wix'

  return (
    <AppLoader>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.content}>
            {headline ? (
              <h1>{headline}</h1>
            ) : showTrial ? (
              <h1>Start your 7 day free trial of Inventora Business!</h1>
            ) : (
              <h1>Get started with Inventora Business today!</h1>
            )}
            <UpgradeForm
              hideCancel
              redirect={redirect || '/materials'}
              hideYearly={hideYearly}
              onPay={onPay}
              provider={provider}
            />
            {showTrial && (
              <p className={styles.trialWarning}>
                You won’t be charged anything if you cancel in the next 7 days.
              </p>
            )}
          </div>
        </div>
      </div>
    </AppLoader>
  )
}
