import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrganizationId } from '../../../ducks/users'
import { createView, getNextPosition } from '../../../ducks/views'
import Button from '../Button'
import Menu from '../Menu'

import styles from './Navbar.module.css'

export default function Add() {
  const dispatch = useDispatch()
  const orgId = useSelector(getCurrentOrganizationId)
  const history = useHistory()
  const nextOrder = useSelector(getNextPosition)

  const handleCreate = useCallback(async () => {
    const name = window.prompt('View name:')

    if (name === null) {
      return
    }

    const { value } = await dispatch(
      createView(orgId, { name, order: nextOrder })
    )

    const { id } = value.data

    history.push(`/views/${id}`)
  }, [orgId, history, nextOrder])

  const handleCreateFolder = useCallback(async () => {
    const query = window.location.search
    const path = window.location.pathname

    const createFolderURL = `${path}/folders/new${query}`

    history.push(createFolderURL)
  }, [])

  const options = useMemo(
    () => [
      {
        label: 'Add View',
        onClick: handleCreate,
      },
      {
        label: 'Add Folder',
        onClick: handleCreateFolder,
      },
    ],
    [handleCreate]
  )

  return (
    <div className={styles.add}>
      <Menu options={options}>
        <Button text icon="add">
          New
        </Button>
      </Menu>
    </div>
  )
}
