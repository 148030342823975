const INTEGRATIONS = {
  shopify: 'Shopify',
  etsy: 'Etsy',
  wix: 'Wix',
}

// Combines shopify product ID & variant into one
const prepareMetadata = resource => {
  if (!(resource && resource.metadata)) {
    return resource && resource.metadata
  }

  const {
    shopifyProductId,
    shopifyVariantId,
    etsyListingId,
    etsyVariantId,
    wixProductId,
    wixVariantId,
    ...rest
  } = resource.metadata

  const shopifyKey =
    shopifyProductId && shopifyVariantId
      ? `${shopifyProductId}~${shopifyVariantId}`
      : undefined

  const etsyKey =
    etsyListingId && etsyVariantId
      ? `${etsyListingId}~${etsyVariantId}`
      : undefined

  const wixKey =
    wixProductId && wixVariantId ? `${wixProductId}~${wixVariantId}` : undefined

  const result = {
    ...rest,
    shopifyProductId: shopifyKey,
    etsyProductId: etsyKey,
    wixProductId: wixKey,
  }

  return result
}

const expandMetadata = metadata => {
  const { shopifyProductId, etsyProductId, wixProductId, ...result } = metadata

  if (shopifyProductId) {
    const [productId, variantId] = shopifyProductId.split('~')

    result.shopifyProductId = productId && +productId
    result.shopifyVariantId = variantId && +variantId
  }

  if (etsyProductId) {
    const [etsyListingId, etsyVariantId] = etsyProductId.split('~')

    result.etsyListingId = etsyListingId
    result.etsyVariantId = etsyVariantId
  }

  if (wixProductId) {
    const [productId, variantId] = wixProductId.split('~')

    result.wixProductId = productId
    result.wixVariantId = variantId
  }

  return result
}

module.exports = {
  INTEGRATIONS,
  prepareMetadata,
  expandMetadata,
}
