import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import {
  getOrganizationUsers,
  fetchOrganizationUsers,
  cancelInvitation,
  removeUser,
} from '../../../ducks/organization-users'

import { getCurrentUser, getPlan } from '../../../ducks/users'
import Card from '../../Shared/LegacyCard'
import { IconButton } from '../../Shared/Icon'
import EmptyState from '../../Shared/EmptyState'
import UpgradePrompt from '../../Shared/UpgradePrompt'
import Loading from '../../Shared/Loading'
import Button from '../../Shared/Button'

import styles from './Users.module.css'

const Item = function UserItem({ user, isCurrentUser, organizationId }) {
  const { name, email, invitation } = user
  const role = (user.OrganizationUser || {}).owner ? 'Owner' : null

  const dispatch = useDispatch()

  const handleDelete = useCallback(() => {
    if (invitation) {
      dispatch(cancelInvitation(organizationId, user.id))
    } else {
      if (window.confirm(`Are you sure you want to remove ${name}?`)) {
        dispatch(removeUser(organizationId, user.id))
      }
    }
  }, [user.id, dispatch, invitation])

  let title = name
  let subtitle = email

  if (invitation) {
    title = email
    subtitle = 'Invited'
  }

  return (
    <div
      className={classNames(styles.userItem, invitation && styles.invitedUser)}
    >
      <div className={styles.userInfo}>
        <h3>
          {title} {isCurrentUser ? '(You)' : ''}
        </h3>
        <p>{subtitle}</p>
      </div>
      <div className={styles.userMeta}>
        <h5 className={styles.role}>{role}</h5>
        {!isCurrentUser && (
          <IconButton color="lightGrey" icon="delete" onClick={handleDelete} />
        )}
      </div>
    </div>
  )
}

export default function OrganizationUsers({ organizationId }) {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const users = useSelector(getOrganizationUsers(organizationId))
  const plan = useSelector(getPlan)

  useEffect(() => {
    dispatch(fetchOrganizationUsers(organizationId))
  }, [organizationId, dispatch])

  if (!users || !currentUser) {
    return (
      <Card>
        <h2>Team Members</h2>
        <EmptyState tight>
          <Loading />
        </EmptyState>
      </Card>
    )
  }

  return (
    <Card>
      <h2>Team Members</h2>
      <div className={styles.users}>
        {users.map(user => (
          <Item
            key={user.id}
            user={user}
            isCurrentUser={user.id === currentUser.id}
            organizationId={organizationId}
          />
        ))}
      </div>
      {plan ? (
        <div className={styles.inviteButton}>
          <Button color="grey" icon="add" to="/settings/invite">
            Invite Team Member
          </Button>
        </div>
      ) : (
        <UpgradePrompt>Upgrade to add a team member</UpgradePrompt>
      )}
    </Card>
  )
}
