import React, { useEffect } from 'react'
import QS from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { useHistory, Link } from 'react-router-dom'

import { acceptInvitation } from '../../ducks/users'
import { getInvitation, fetchInvitation } from '../../ducks/invitations'
import AuthPage from '../Shared/AuthPage'
import { FullScreenLoader } from '../Shared/Loading'
import FormError from '../Shared/Forms/FormError'
import SignupForm from './SignupForm'

export default function AcceptInvitation({ match }) {
  const { invitationId } = match.params
  const dispatch = useDispatch()
  const history = useHistory()
  const invitation = useSelector(getInvitation(invitationId))

  useEffect(() => {
    dispatch(fetchInvitation(invitationId))
  }, [dispatch, invitationId])

  const handleSignup = async data => {
    const { redirect } = QS.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    try {
      await dispatch(acceptInvitation(invitationId, data))
      const defaultRedirect = invitation.allowChangeEmail ? '/products' : '/'
      history.push(redirect || defaultRedirect)
    } catch (err) {
      let msg =
        'An unknown error occurred. ' +
        'Please check your information and try again.'

      if (err.response && err.response.data && err.response.data.error) {
        msg = err.response.data.error
      }

      throw new SubmissionError({
        _error: msg,
      })
    }
  }

  if (!invitation) {
    return (
      <AuthPage>
        <FullScreenLoader />
      </AuthPage>
    )
  }

  const initialValues = {
    email: invitation.email,
  }

  return (
    <AuthPage
      links={
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      }
      title={
        invitation.allowChangeEmail
          ? 'Create your Inventora account'
          : undefined
      }
    >
      {invitation.error ? (
        <FormError>{invitation.error}</FormError>
      ) : (
        <SignupForm
          invitation
          allowChangeEmail={invitation.allowChangeEmail}
          initialValues={initialValues}
          onSubmit={handleSignup}
        />
      )}
    </AuthPage>
  )
}
