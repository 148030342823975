import React from 'react'
import Button from '../Button'
import { IconButton } from '../Icon'
import MenuTrigger from '../Menu'

export default function Overflow({
  options,
  className,
  activeClassName,
  noPortal,
  button = false,
  align = 'right',
}) {
  return (
    <MenuTrigger
      options={options}
      align={align}
      className={className}
      activeClassName={activeClassName}
      noPortal={noPortal}
    >
      {!button ? (
        <IconButton icon="more" color="grey" />
      ) : (
        <Button icon="more" />
      )}
    </MenuTrigger>
  )
}
