import React from 'react'
import pick from 'lodash/pick'
import { useSelector, useDispatch } from 'react-redux'
import { reset } from 'redux-form'

import { processError } from '../../utils/errors'
import { getCurrentUser, updateCurrentUser } from '../../ducks/users'
import { FullScreenLoader } from '../Shared/Loading'
import Form, { FORM_NAME } from './Form'

const delay = ms =>
  new Promise(resolve => {
    window.setTimeout(resolve, ms)
  })

export default function Settings() {
  const user = useSelector(getCurrentUser)
  const dispatch = useDispatch()

  const handleSubmit = async values => {
    try {
      await dispatch(updateCurrentUser(values))
      await delay(1)
      dispatch(reset(FORM_NAME))
    } catch (err) {
      processError(err)
    }
  }

  if (!user) {
    return <FullScreenLoader />
  }

  const initialValues = pick(user, ['email', 'name'])

  return <Form onSubmit={handleSubmit} initialValues={initialValues} />
}
