import React from 'react'
import { reduxForm, Field } from 'redux-form'

import WrappedInput from '../Shared/Forms/WrappedInput'
import Button from '../Shared/Button'
import Card from '../Shared/LegacyCard'
import FormError from '../Shared/Forms/FormError'

import styles from './Auth.module.css'

const FORM_NAME = 'loginForm'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Please enter your email'
  }

  if (!values.password) {
    errors.password = 'Please enter your password'
  }

  return errors
}

const Form = function LoginForm({ handleSubmit, submitting, error }) {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <div className={styles.inputs}>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            component={WrappedInput}
          />
          <Field
            name="password"
            placeholder="Password"
            type="password"
            component={WrappedInput}
          />
        </div>
        {error && <FormError>{error}</FormError>}
        <Button primary block loading={submitting}>
          Login
        </Button>
      </Card>
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(Form)
