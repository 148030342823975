import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as API from '../../../utils/api'
import { getCurrentOrganizationId } from '../../../ducks/users'
import { FullScreenLoader } from '../../Shared/Loading'
import AppLoader from '../../App/AppLoader'

const StripePortalRedirect = function StripePortalRedirect() {
  const orgId = useSelector(getCurrentOrganizationId)
  const history = useHistory()

  const redirect = async () => {
    try {
      const { url } = (await API.createPortalSession(orgId)).data
      window.location = url
    } catch (err) {
      const error =
        (err.response && err.response.data && err.response.data.error) ||
        'An unknown error occurred'

      window.alert(error)
      history.push('/')
    }
  }

  useEffect(() => {
    redirect()
  }, [orgId])

  return <FullScreenLoader />
}

export default function StripePortalRedirectWrapper() {
  return (
    <AppLoader>
      <StripePortalRedirect />
    </AppLoader>
  )
}
