const units = require('./units')
const databases = require('./databases')
const currencies = require('./currencies')
const { matchLists } = require('./matching')
const resources = require('./resources')
const arrays = require('./arrays')
const { ValidationError } = require('./errors')
const reports = require('./reports')
const indexing = require('./indexing')
const timeTravel = require('./time-travel')
const search = require('./search')
const colors = require('./colors')
const integrations = require('./integrations')
const typography = require('./typography')
const diffing = require('./diffing')
const tasks = require('./tasks')
const numbers = require('./numbers')
const wholesale = require('./wholesale')

module.exports = {
  ...resources,
  ...units,
  ...arrays,
  ...reports,
  ...indexing,
  ...timeTravel,
  ...search,
  ...colors,
  ...integrations,
  ...typography,
  ...diffing,
  ...currencies,
  ...tasks,
  ...numbers,
  ...wholesale,
  ...databases,
  matchLists,
  ValidationError,
}
