import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { reducer as formReducer } from 'redux-form'

import * as reducers from './ducks'

const middleware = applyMiddleware(thunkMiddleware, promiseMiddleware)

export default createStore(
  combineReducers({ ...reducers, form: formReducer }),
  middleware
)
