import * as API from '../../utils/api'
import { identifyUser } from '../../utils/analytics'
import { setCookie, removeCookie } from '../../utils/cookie'

import { getOrganization } from '../organizations'

export const SWITCH_ORGANIZATION = Symbol('SWITCH_ORGANIZATION')
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
const LOGIN = 'LOGIN'
const SIGNUP = 'SIGNUP'
const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export const LOGOUT = Symbol('LOGOUT')

const INITIAL_STATE = {
  currentUser: null,
  currentOrganizationId: null,
}

API.setAuthToken(window.localStorage.getItem('authToken'))

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${FETCH_CURRENT_USER}_FULFILLED`) {
    let profile = action.payload.data
    let { lastOrganizationId } = profile

    profile = {
      ...profile,
      Organizations: profile.Organizations.map(org => org.id),
    }

    if (!profile.Organizations.includes(lastOrganizationId)) {
      lastOrganizationId = profile.Organizations[0]
    }

    identifyUser(profile)
    setCookie()

    return {
      ...state,
      currentUser: profile,
      currentOrganizationId: lastOrganizationId,
    }
  }

  if (action.type === `${UPDATE_CURRENT_USER}_FULFILLED`) {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        ...action.payload.data,
      },
    }
  }

  if (action.type === SWITCH_ORGANIZATION) {
    let { id } = action.meta || {}

    if (id) {
      return { ...state, currentOrganizationId: id }
    }
  }

  if (
    action.type === `${LOGIN}_FULFILLED` ||
    action.type === `${SIGNUP}_FULFILLED` ||
    action.type === `${ACCEPT_INVITATION}_FULFILLED`
  ) {
    let { authToken } = action.payload.data

    localStorage.setItem('authToken', authToken)
    API.setAuthToken(authToken)

    return state
  }

  if (action.type === LOGOUT) {
    localStorage.removeItem('authToken')
    removeCookie()
    API.setAuthToken(null)

    return INITIAL_STATE
  }

  return state
}

// Actions

export const fetchCurrentUser = () => ({
  type: FETCH_CURRENT_USER,
  payload: API.fetchCurrentUser(),
})

export const updateCurrentUser = values => ({
  type: UPDATE_CURRENT_USER,
  payload: API.saveUser(values),
})

export const changeOrganization = id => dispatch => {
  const prom = API.saveUser({ lastOrganizationId: id, lastViewId: null })

  prom.catch(err => {
    throw err
  })

  dispatch({
    type: SWITCH_ORGANIZATION,
    meta: { id },
  })

  return prom
}

export const login = data => ({
  type: LOGIN,
  payload: API.login(data),
})

export const loginBypass = authToken => ({
  type: `${LOGIN}_FULFILLED`,
  payload: {
    data: { authToken },
  },
})

export const signup = data => ({
  type: SIGNUP,
  payload: API.signup(data),
})

export const acceptInvitation = (invitationId, data) => ({
  type: ACCEPT_INVITATION,
  payload: API.acceptInvitation(invitationId, data),
})

export const logout = () => dispatch => {
  window.setTimeout(() => {
    dispatch({ type: SWITCH_ORGANIZATION })
  }, 100)

  dispatch({
    type: LOGOUT,
  })
}

// Selectors

export const getCurrentUser = state => {
  return state.users.currentUser
}

export const getCurrentOrganization = state => {
  let orgId = getCurrentOrganizationId(state)
  let currentOrg = getOrganization(orgId)(state)

  return currentOrg
}

export const getPlan = state => {
  const org = getCurrentOrganization(state)
  const plan = org.activePlan

  return plan
}

export const getCurrentOrganizationId = state => {
  return state.users.currentOrganizationId
}

export const getOrganizations = state => {
  let user = getCurrentUser(state)

  if (!user) {
    return []
  }

  let ids = user && user.Organizations

  return ids.map(id => getOrganization(id)(state)).filter(itm => itm)
}
