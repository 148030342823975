import React, { useState, useCallback } from 'react'
import EmojiPicker, { Emoji } from 'emoji-picker-react'
import { getUnifiedEmoji } from '../../../../utils/emojis'
import Icon from '../../Icon'
import styles from './EmojiPicker.module.css'

export default function WrappedEmojiPicker({ label, defaultIcon, input }) {
  const { value, onChange } = input
  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleClickEmoji = useCallback(
    emojiObject => {
      onChange(emojiObject.names[0])
      setOpen(false)
    },
    [onChange]
  )

  const unified = value && getUnifiedEmoji(value)

  return (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.value} onClick={toggleOpen}>
        {value ? (
          <Emoji unified={unified} />
        ) : (
          <Icon icon={defaultIcon} color="grey" />
        )}
      </div>
      {open && (
        <>
          <div className={styles.backdrop} onClick={toggleOpen} />
          <div className={styles.picker}>
            <EmojiPicker skinTonesDisabled onEmojiClick={handleClickEmoji} />
          </div>
        </>
      )}
    </div>
  )
}
