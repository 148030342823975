import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { goBack } from '../../../utils/history'
import { processError } from '../../../utils/errors'
import { createFolder } from '../../../ducks/views'
import { getCurrentOrganizationId } from '../../../ducks/users'
import CenteredModal from '../../Shared/CenteredModal'
import Form from './Form'

export default function CreateFolder() {
  const orgId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(createFolder(orgId, values))
        goBack()
      } catch (err) {
        processError(err)
      }
    },
    [dispatch, orgId]
  )

  return (
    <CenteredModal>
      <CenteredModal.Header>Add Folder</CenteredModal.Header>
      <Form onSubmit={handleSubmit} />
    </CenteredModal>
  )
}
