import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { goBack } from '../../../../utils/history'
import { processError } from '../../../../utils/errors'

import {
  fetchDatabase,
  getDatabase,
  updateDatabase,
} from '../../../../ducks/databases'

import Modal from '../../../Shared/Modal'
import EmptyState from '../../../Shared/EmptyState'
import Form from './Form'

export default function EditDatabase() {
  const match = useRouteMatch()

  const { databaseId } = match.params
  const dispatch = useDispatch()
  const database = useSelector(getDatabase(databaseId))

  useEffect(() => {
    dispatch(fetchDatabase(databaseId))
  }, [databaseId])

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(updateDatabase(databaseId, values))

        goBack()
      } catch (err) {
        processError(err)
      }
    },
    [databaseId]
  )

  return (
    <Modal>
      <Modal.Header onClose={goBack}>
        <h1>Edit Database</h1>
      </Modal.Header>
      {database?.credentials === undefined ? (
        <EmptyState loading />
      ) : (
        <Form
          onSubmit={handleSubmit}
          onClose={goBack}
          databaseId={databaseId}
          initialValues={database}
        />
      )}
    </Modal>
  )
}
