import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { goBack } from '../../../../utils/history'
import { processError } from '../../../../utils/errors'
import { getCurrentOrganizationId } from '../../../../ducks/users'
import { createDatabase } from '../../../../ducks/databases'
import Modal from '../../../Shared/Modal'
import Form from './Form'

export default function CreateDatabase({ match }) {
  const organizationId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()

  const handleClose = () => {
    goBack()
  }

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(createDatabase(organizationId, values))

        handleClose()
      } catch (err) {
        processError(err)
      }
    },
    [organizationId]
  )

  return (
    <Modal>
      <Modal.Header onClose={handleClose}>
        <h1>Add Database</h1>
      </Modal.Header>
      <Form onSubmit={handleSubmit} onClose={handleClose} />
    </Modal>
  )
}
