import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import EventSuppressor from '../../EventSuppressor'
import styles from './WrappedInput.module.css'

const Arrow = function Arrow({ value, onChange, type, min, max }) {
  const intervalRef = useRef(null)
  const timeoutRef = useRef(null)

  const handleMouseDown = useCallback(
    e => {
      let newValue = value

      const handleChange = (shiftPressed = false) => {
        const absDiff = shiftPressed ? 10 : 1
        const diff = type === 'up' ? absDiff : -absDiff
        const parsed = parseFloat(newValue) || 0
        newValue = (+(parsed + diff).toFixed(8)).toString()

        if (+newValue > +max) return onChange(String(max))
        if (+newValue < +min) return onChange(String(min))

        onChange(newValue)
      }

      e.preventDefault()
      e.stopPropagation()
      const { shiftKey } = e
      handleChange(shiftKey)

      // Set timer
      timeoutRef.current = setTimeout(() => {
        intervalRef.current = setInterval(() => {
          handleChange(shiftKey)
        }, 100)
      }, 500)
    },
    [value, onChange]
  )

  const handleMouseUp = useCallback(() => {
    clearTimeout(timeoutRef.current)
    clearInterval(intervalRef.current)
  })

  return (
    <button
      type="button"
      tabIndex={-1}
      className={classNames(styles.arrow, styles[type])}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <span className={styles.arrowSub}>
        {type === 'up' ? '+' : '–'}
        <span className={styles.arrowIndicator} />
      </span>
    </button>
  )
}

export default function Arrows({ value, onChange, min, max }) {
  return (
    <EventSuppressor className={styles.arrows}>
      <Arrow value={value} onChange={onChange} type="up" max={max} />
      <Arrow value={value} onChange={onChange} type="down" min={min} />
    </EventSuppressor>
  )
}
