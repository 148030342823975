import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { CURRENCY_OPTIONS } from '@sqldash/common'

import Card from '../../Shared/LegacyCard'
import Button from '../../Shared/Button'
import WrappedInput from '../../Shared/Forms/WrappedInput'
import SearchInput from '../../Shared/Forms/SearchInput'
import FormError from '../../Shared/Forms/FormError'

const FORM_NAME = 'settingsBasicInfo'

const validate = values => {
  let errors = {}

  if (!values.name) {
    errors.name = 'Please enter your company name'
  }

  return errors
}

const Form = function InfoForm({ handleSubmit, submitting, pristine, error }) {
  return (
    <Card
      component="form"
      onSubmit={handleSubmit}
      actions={
        pristine ? null : (
          <Button primary loading={submitting}>
            Save
          </Button>
        )
      }
    >
      <h2>Basic Information</h2>
      <Field
        name="name"
        component={WrappedInput}
        label="Business Name"
        autoComplete="off"
      />
      <Field
        name="website"
        component={WrappedInput}
        label="Website"
        autoComplete="off"
      />
      <Field
        name="currency"
        component={SearchInput}
        options={CURRENCY_OPTIONS}
        label="Currency"
      />
      {error && <FormError>{error}</FormError>}
    </Card>
  )
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
})(Form)
