import { useEffect, useState } from 'react'
import { indexOf } from '@sqldash/common'
import { getExpandedItems } from './dragging'

export const COMMAND_KEY =
  navigator.platform === 'MacIntel' ? 'metaKey' : 'ctrlKey'

export const COMMAND_SYMBOL = COMMAND_KEY === 'metaKey' ? '⌘' : 'Ctrl-'

const COMMAND_KEY_CODE = navigator.platform === 'MacIntel' ? 91 : 17

export const listenForCommand = (commandKey, callback) => {
  const func = e => {
    if (e[COMMAND_KEY] && e.key.toLowerCase() === commandKey.toLowerCase()) {
      e.preventDefault()
      e.stopPropagation()

      callback(e)
    }
  }

  window.addEventListener('keydown', func)

  return () => {
    window.removeEventListener('keydown', func)
  }
}

export const getCommandLabel = commandKey => {
  return `${COMMAND_SYMBOL}${commandKey.toUpperCase()}`
}

export const getNextTarget = (rawItems, focussedId, prev = false) => {
  const expandedItems = getExpandedItems(rawItems)
  const items = expandedItems.filter(itm => !itm.data.__isFolder__)

  if (!focussedId) return items[0]?.id || null

  const index = indexOf(items, itm => itm.id === focussedId)
  const diff = prev ? -1 : 1
  const newIndex = (index + diff + items.length) % items.length
  const newTarget = items[newIndex]

  return newTarget?.id || null
}

export const getNumericCommands = rawItems => {
  const results = {}
  let viewId

  for (let i = 1; i <= 5; i += 1) {
    viewId = getNextTarget(rawItems, viewId)

    if (!viewId || results[viewId]) break

    results[viewId] = `${i}`
  }

  return results
}

export const useCommandPressed = () => {
  const [pressed, setPressed] = useState(false)

  useEffect(() => {
    const func = e => {
      if (e.keyCode === COMMAND_KEY_CODE) {
        setPressed(!pressed)
      }
    }

    const evt = pressed ? 'keyup' : 'keydown'

    window.addEventListener(evt, func)

    return () => {
      window.removeEventListener(evt, func)
    }
  }, [pressed])

  return pressed
}
