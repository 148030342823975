import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { goBack } from '../../../utils/history'
import { processError } from '../../../utils/errors'
import { getCurrentOrganizationId } from '../../../ducks/users'
import { inviteUser } from '../../../ducks/organization-users'
import Modal from '../../Shared/Modal'
import Button from '../../Shared/Button'
import Form from './InviteForm'

export default function InviteUser({ match }) {
  const [invitation, setInvitation] = useState(null)
  const organizationId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()

  const handleClose = () => {
    goBack()
  }

  const handleSubmit = useCallback(
    async values => {
      let { email, role } = values

      try {
        const result = await dispatch(inviteUser(organizationId, email, role))
        const invite = result.value.data
        setInvitation(invite)
      } catch (err) {
        processError(err)
      }
    },
    [organizationId]
  )

  const initialValues = {
    role: 'editor',
  }

  return (
    <Modal showCloseButton onClose={handleClose}>
      {invitation ? (
        <div>
          {invitation.existingUser ? (
            <>{invitation.email} has been added to your team!</>
          ) : (
            <>Invitation sent to {invitation.email}!</>
          )}
          <Button.Group align="center">
            <Button primary onClick={handleClose}>
              Done
            </Button>
          </Button.Group>
        </div>
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          onClose={handleClose}
        />
      )}
    </Modal>
  )
}
