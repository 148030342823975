import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { useHistory, Link } from 'react-router-dom'

import { getRedirectURL } from '../../utils/auth'
import { convert } from '../../utils/analytics'
import { signup } from '../../ducks/users'
import AuthPage from '../Shared/AuthPage'
import SignupForm from './SignupForm'

export default function Signup() {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSignup = useCallback(
    async data => {
      try {
        const user = (await dispatch(signup(data))).value.data
        convert(user)

        history.push(getRedirectURL())
      } catch (err) {
        // Re-throw for sentry
        window.setTimeout(() => {
          throw err
        }, 0)

        throw new SubmissionError({
          _error:
            err.response?.data?.error ||
            'An unknown error occurred. ' +
              'Please check your information and try again.',
        })
      }
    },
    [history, dispatch]
  )

  const loginURL = `/login${window.location.search}`

  return (
    <AuthPage
      links={
        <p>
          Already have an account? <Link to={loginURL}>Login</Link>
        </p>
      }
    >
      <SignupForm onSubmit={handleSignup} />
    </AuthPage>
  )
}
