import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { getPlan } from '../../../ducks/users'
import Icon from '../Icon'
import styles from './UpgradePrompt.module.css'

export default function UpgradePrompt({ children }) {
  const match = useRouteMatch()
  const url = match.url
  const plan = useSelector(getPlan)

  if (plan) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Icon tight icon="sparkle" color="yellow" />
      {children && (
        <span>
          {' '}
          <Link replace to={`${url}/upgrade`}>
            {children}
          </Link>
        </span>
      )}
    </div>
  )
}
