import React, { useContext } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { MenuContext } from '../Menu'
import Loading from '../Loading'

import styles from './Icon.module.css'
import buttonStyles from './IconButton.module.css'

export default function Icon(props) {
  const {
    icon,
    big,
    lg,
    xl,
    color,
    placeholder,
    tight,
    inline,
    className,
    ...otherProps
  } = props

  return (
    <span
      {...otherProps}
      className={classNames(styles.icon, className, styles[`icon-${icon}`], {
        [styles.big]: big || lg,
        [styles.xl]: xl,
        [styles.placeholder]: placeholder,
        [styles[`color-${color}`]]: color,
        [styles.tight]: tight,
        [styles.inline]: inline,
      })}
    />
  )
}

export const IconButton = function IconButton({
  onClick,
  large,
  disabled,
  className,
  loading,
  to,
  href,
  color,
  expanded,
  target,
  replace,
  ...props
}) {
  const IconButton = to ? Link : href ? 'a' : 'div'
  const iconColor = color === 'black' ? 'white' : color
  const menu = useContext(MenuContext)

  return (
    <IconButton
      className={classNames(buttonStyles.wrapper, className, {
        [buttonStyles[`color-${color}`]]: color,
        [buttonStyles.large]: large,
        [buttonStyles.disabled]: disabled,
        [buttonStyles.active]: expanded || menu.open,
      })}
      onClick={onClick}
      to={to}
      replace={replace}
      href={href}
      rel={href ? 'noopener noreferrer' : null}
      target={target}
    >
      {loading ? (
        <Loading small />
      ) : (
        <Icon {...props} color={iconColor} className={buttonStyles.icon} />
      )}
    </IconButton>
  )
}
