import React from 'react'
import { reduxForm, Field } from 'redux-form'
import WrappedInput from '../../Shared/Forms/WrappedInput'
import FormError from '../../Shared/Forms/FormError'
import Card from '../../Shared/LegacyCard'
import Button from '../../Shared/Button'

const FORM_NAME = 'inviteUserForm'

const validate = values => {
  let errors = {}

  if (!values.email) {
    errors.email = 'Please enter an email.'
  }

  return errors
}

const Form = function InviteForm({ handleSubmit, submitting, onClose, error }) {
  return (
    <Card
      component="form"
      onSubmit={handleSubmit}
      actions={
        <>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button primary loading={submitting}>
            Invite
          </Button>
        </>
      }
    >
      <h1>Invite Team Member</h1>
      <Field
        autoFocus
        component={WrappedInput}
        name="email"
        label="Email"
        autoComplete="off"
        type="email"
      />
      {error && <FormError>{error}</FormError>}
    </Card>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(Form)
