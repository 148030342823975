const { getUnitLabel } = require('./resources')
const { ValidationError } = require('./errors')

const ratios = {
  pieces: 1,

  // Weight
  'weight.lbs': 16,
  'weight.oz': 1,
  'weight.kg': 35.274,
  'weight.grams': 0.035274,

  // Length
  'length.ft': 12,
  'length.yd': 36,
  'length.in': 1,
  'length.m': 39.36996,
  'length.cm': 0.393701,

  // Area
  'area.sqft': 144,
  'area.sqin': 1,
  'area.sqm': 1550,
  'area.sqcm': 0.155,

  // Volume
  'volume.oz': 1,
  'volume.ga': 128,
  'volume.qt': 32,
  'volume.liters': 33.814,
  'volume.ml': 0.033814,
}

const unitFactor = (unitUsed, supplyUnit) => {
  const unitUsedCategory = (unitUsed || '').split('.')[0]
  const supplyUnitCategory = (supplyUnit || '').split('.')[0]

  // Throw error for units of different categories of units
  if (
    unitUsed &&
    supplyUnit &&
    unitUsed.split('.')[0] !== supplyUnit.split('.')[0]
  ) {
    const usedLabel = getUnitLabel(unitUsed, 0, true)
    const supplyLabel = getUnitLabel(supplyUnit, 0, true)

    throw new ValidationError(
      `Incompatible units: ${usedLabel}, ${supplyLabel}`
    )
  }

  if (
    unitUsed === supplyUnit ||
    !ratios[unitUsed] ||
    !ratios[supplyUnit] ||
    unitUsedCategory !== supplyUnitCategory
  ) {
    return 1
  }

  return ratios[unitUsed] / ratios[supplyUnit]
}

const calculateUnitPrice = product => {
  const { supplies } = product
  let error = null

  const prices = supplies
    .map(supply => {
      try {
        if (supply && supply.supply) {
          return (
            supply.quantityUsed *
            unitFactor(supply.unitType, supply.supply.unitType) *
            supply.supply.unitPrice
          )
        }
      } catch (err) {
        if (err instanceof ValidationError) {
          error = err.message
        }
      }
    })
    .filter(price => price)

  let totalPrice = prices.reduce((a, b) => a + b, 0)

  if (prices.length === 0) {
    totalPrice = undefined
  }

  return {
    error,
    unitPrice: totalPrice,
  }
}

const getUnitPrice = resource => {
  if (resource.type === 'supply') {
    return { unitPrice: resource.unitPrice }
  }

  const price = calculateUnitPrice(resource)

  return price
}

const getSupplyPrice = resourceRelationship => {
  const { supply, unitType, quantityUsed } = resourceRelationship
  const unitUsed = unitType || supply.unitType
  let price
  let error

  try {
    price =
      quantityUsed * unitFactor(unitUsed, supply.unitType) * supply.unitPrice
  } catch (err) {
    error = err.message
  }

  return {
    error,
    price,
  }
}

module.exports = {
  unitFactor,
  getUnitPrice,
  getSupplyPrice,
}
