import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getView } from '../../../ducks/views'
import { getViewData } from '../../../ducks/data'
import EmptyState from '../../Shared/EmptyState'
import Table from './Table'
import Error from './Error'
import Pagination from './Pagination'
import Tabs from './Tabs'
import Chart from './Chart'

export default function ViewData() {
  const { viewId } = useParams()
  const viewData = useSelector(getViewData(viewId))
  const view = useSelector(getView(viewId))
  const { visualization } = view

  if (!viewData || viewData.loading) {
    return <EmptyState loading />
  }

  return (
    <>
      <Tabs />
      {viewData.error ? (
        <Error error={viewData.error} />
      ) : visualization === 'chart' ? (
        <Chart />
      ) : (
        <>
          <Table
            result={viewData.result}
            loading={viewData.loading}
            view={view}
          />
          <Pagination />
        </>
      )}
    </>
  )
}
